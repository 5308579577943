import { useQuery } from '@apollo/react-hooks';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { NUM_ITEMS_PER_PAGE } from '../../../constants';
import { getTimeString, pagerize } from '../../../utils';
import Pagination from '../../Pagination/Pagination';
import {
  UnmountableCustomModalProvider,
  UnmountableModalWrapper,
  useUnmountableCustomModalContext,
} from '../PlusDetail/UnmountableCustomModal';
import CreateFraudEventLogModal from './CreateFraudEventLogModal';
import styles from './Fraud.module.scss';
import { getFraudEventLogs } from './gql-query';

type LogRowProps = {
  createdAt: number;
  createdBy: string;
  message: string;
};

function useFraudEventLogs() {
  const { consumerId } = useParams() as { consumerId: string };

  const {
    data = {},
    loading,
    error,
  } = useQuery(getFraudEventLogs, {
    variables: { consumerId },
  });

  const fraudEventLogs = data?.fraudManagementEventLogs?.eventLogs || [];

  return { fraudEventLogs, loading, error };
}

const CreateFraudEventLogButton = () => {
  const { onOpen } = useUnmountableCustomModalContext();

  return (
    <>
      <button className={`btn ${styles['btn-primary']}`} onClick={onOpen}>
        ログを作成
      </button>
      <UnmountableModalWrapper className={styles['assessment-modal']}>
        <CreateFraudEventLogModal />
      </UnmountableModalWrapper>
    </>
  );
};

const LogRow = ({ createdAt, createdBy, message }: LogRowProps) => {
  return (
    <tr>
      <td>{getTimeString(new Date(createdAt))}</td>
      <td>{createdBy}</td>
      <td className={styles['message-column']}>{message}</td>
    </tr>
  );
};

const FraudEventLogsTable = () => {
  const { fraudEventLogs = [] } = useFraudEventLogs();
  const [page, setPage] = useState(0);

  const pageCount = fraudEventLogs
    ? Math.ceil(fraudEventLogs.length / NUM_ITEMS_PER_PAGE)
    : 0;

  const pagedFraudFlagEventLogs = pagerize({
    entries: fraudEventLogs,
    page,
    perPage: NUM_ITEMS_PER_PAGE,
  });

  const gotoPage = (p: number) => {
    setPage(p);
  };

  useEffect(() => {
    // page starts from 0
    // pageCount starts from 1
    if (page != 0 && page > pageCount - 1) {
      setPage(pageCount - 1);
    }
  }, [fraudEventLogs]);

  return (
    <div className={`table ${styles['fraud-table']}`}>
      <span className={styles['fraud-table__heading']}>
        <h1>Fraud ログ</h1>
        <UnmountableCustomModalProvider>
          <CreateFraudEventLogButton />
        </UnmountableCustomModalProvider>
      </span>
      <table>
        <thead>
          <tr>
            <th>作成日</th>
            <th>作成者</th>
            <th>メッセージ</th>
          </tr>
        </thead>
        <tbody>
          {pagedFraudFlagEventLogs.length ? (
            pagedFraudFlagEventLogs.map((log) => (
              <LogRow
                key={log.id}
                createdAt={log.createdAt}
                createdBy={log.createdBy}
                message={log.message}
              />
            ))
          ) : (
            <tr>
              <td colSpan={3} className={styles['fraud-table__empty-row']}>
                Fraudログがありません
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {pageCount > 1 && (
        <Pagination pageCount={pageCount} page={page} gotoPage={gotoPage} />
      )}
    </div>
  );
};

export default FraudEventLogsTable;
