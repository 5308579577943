import gql from 'graphql-tag';

export default gql`
  query Search($searchKeyword: ID!) {
    fuzzySearch(query: $searchKeyword) {
      consumers {
        id
        kanjiName
        kanaName
        createdAt
        dateOfBirth
        email
        phone
        status
      }
      paidyPayments {
        id
        consumerId
        consumerName
        amount
        shippingAddress {
          zip
          line1
          line2
          city
          state
        }
        createdAt
        status
        test
        tags
      }
      combiniPayments {
        id
        orderRef
        amount
        createdAt
        status
        test
      }
      chargesByProviderRef {
        chargeId
        orderReference
        amount
        createdAt
        consumerId
        barcodeId
      }
    }
  }
`;
