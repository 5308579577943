import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { currencyFormatter } from '../../../utils';

type ContractProps = {
  contract: {
    [key: string]: any;
  };
};

const Contract = () => {
  const { contract } = useOutletContext() as ContractProps;

  if (!contract) {
    return <div className='na-box' />;
  }

  const addr = contract.contractAddress;

  return (
    <>
      <div className='table striped'>
        <table>
          <thead>
            <tr>
              <th colSpan={2}>契約者様情報</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>氏名</td>
              <td>{`${contract.lastName} ${contract.firstName}`}</td>
            </tr>
            <tr>
              <td>年収</td>
              <td>{currencyFormatter.format(contract.annualIncome)}</td>
            </tr>
            <tr>
              <td>生年月日</td>
              <td>{contract?.dateOfBirth || `N/A`}</td>
            </tr>
            <tr>
              <td>性別</td>
              <td>{contract.gender}</td>
            </tr>
            <tr>
              <td>配偶者の有無</td>
              <td>{contract.maritalStatus}</td>
            </tr>
            <tr>
              <td>同居人数</td>
              <td>{contract.householdSize}</td>
            </tr>
            <tr>
              <td>家賃・住宅ローンの有無</td>
              <td>{contract.mortgage ? '有' : '無'}</td>
            </tr>
            <tr>
              <td>住宅形態</td>
              <td>{contract.residenceType}</td>
            </tr>
            <tr>
              <td>住所</td>
              <td>
                {addr &&
                  `${addr.zip || ''} ${addr.state || ''} ${
                    addr.city || ''
                  } ${addr.line2 || ''} ${addr.line1 || ''}`}
              </td>
            </tr>
            <tr>
              <td>会社名</td>
              <td>{contract.employer}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Contract;
