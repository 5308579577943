import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { getTimeString } from '../../../utils';

type MessagesProps = {
  messages: Array<{
    [key: string]: string | number | any;
  }>;
};

const Messages = () => {
  const { messages } = useOutletContext() as MessagesProps;
  if (!messages || !messages.length) {
    return <div className='na-box' />;
  }

  return (
    <>
      <div className='table'>
        <table>
          <thead>
            <tr>
              <th>作成日</th>
              <th>分類</th>
              <th>ステータス</th>
              <th>メッセージ</th>
            </tr>
          </thead>
          <tbody>
            {messages.map((m, i) => {
              return (
                <tr key={i}>
                  <td>
                    <time>{getTimeString(new Date(m.createdAt))}</time>
                  </td>
                  <td className='uppercase'>{m.kind}</td>
                  <td>
                    <span className={`tag ${m.dispatched ? '' : 'red'}`}>
                      {m.dispatched ? 'Sent' : 'NOT SENT'}
                    </span>
                  </td>
                  <td className='wrap'>{m.message || m.subject || 'N/A'}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Messages;
