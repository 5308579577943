import { useQuery } from '@apollo/react-hooks';
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import { onError } from '../../utils';
import Card from '../Card/Card';
import LoadingPartial from '../Loading/LoadingPartial';
import gqlQuery from './gql-query';

/**
 * Check if the search result has only one result for the given type.
 */
const hasSingleResult = (typeTocheck: string, searchResult: any) => {
  const resultTypes = ['consumers', 'paidyPayments', 'combiniPayments'];

  const otherResultsAreEmpty = resultTypes
    .filter((type) => type !== typeTocheck) // Exclude the current type
    .every((type) => searchResult[type].length === 0);

  return searchResult[typeTocheck].length === 1 && otherResultsAreEmpty;
};

/**
 * Redirect to the exact match page if there is only one result,
 */
const redirectExactMatch = (
  searchResult: any,
  navigate: (to: string) => void
) => {
  if (hasSingleResult('consumers', searchResult)) {
    const consumerId = searchResult.consumers[0].id;
    navigate(`/consumers/${consumerId}/details`);
    return null;
  }

  if (hasSingleResult('paidyPayments', searchResult)) {
    const paymentId = searchResult.paidyPayments[0].id;
    navigate(`/payments/${paymentId}/auth`);
    return null;
  }

  if (hasSingleResult('combiniPayments', searchResult)) {
    const combinedPaymentId = searchResult.combiniPayments[0].id;
    navigate(`/combini-payments/${combinedPaymentId}`);
    return null;
  }
};

const SearchPage = () => {
  const searchKeyword = useSelector((state: any) => state.searchKeyword.value);
  const navigate = useNavigate();
  const { loading, data } = useQuery(gqlQuery, {
    onError,
    skip: !searchKeyword, // Skip the query if searchKeyword is empty
    variables: { searchKeyword },
  });

  if (!searchKeyword) {
    return (
      <Card>
        <div className='na-box' />
      </Card>
    );
  }

  if (loading) {
    return <LoadingPartial />;
  }

  if (!data || !data.fuzzySearch) {
    return (
      <Card>
        <div className='na-box' />
      </Card>
    );
  }

  const { consumers, combiniPayments, paidyPayments, chargesByProviderRef } =
    data.fuzzySearch;

  redirectExactMatch(data.fuzzySearch, navigate);

  return (
    <div>
      <Card>
        <nav className='tabs'>
          <NavLink to='/search/payments'>ペイメント</NavLink>
          <NavLink to='/search/consumers'>顧客名</NavLink>
          <NavLink to='/search/combini-payments'>コンビニ払い</NavLink>
          <NavLink to='/search/duplicate-settlements'>
            Suspicious Duplicate Settlement
          </NavLink>
        </nav>
        <Outlet
          context={{
            payments: paidyPayments,
            consumers,
            combiniPayments,
            duplicateCharges: chargesByProviderRef,
          }}
        />
      </Card>
    </div>
  );
};

export default SearchPage;
