import { useQuery } from '@apollo/react-hooks';
import gqlQuery from './gql-query';

export const FRAUD_PERMISSION = {
  ASSESSMENT_EXCLUSIONS_READ: 'assessment_exclusions.read',
  ASSESSMENT_EXCLUSIONS_WRITE: 'assessment_exclusions.write',
  ASSESSMENT_REPORTS_READ: 'assessment_reports.read',
  ASSESSMENT_REPORTS_WRITE: 'assessment_reports.write',
  PAYMENT_FRAUD_FLAGS_WRITE: 'payment_fraud_flags.write',
  PAYMENT_FRAUD_FLAGS_READ: 'payment_fraud_flags.read',
};

export const useFraudPermissionQuery = () => {
  const { data = {} } = useQuery(gqlQuery);

  const permissions = data?.fraudManagementPermissions?.permissions || [];

  return permissions.map((permission) => permission.value);
};

export const useIsShowAssessmentReport = () => {
  const permissions = useFraudPermissionQuery();

  return (
    permissions.includes(FRAUD_PERMISSION.ASSESSMENT_REPORTS_READ) ||
    permissions.includes(FRAUD_PERMISSION.ASSESSMENT_REPORTS_WRITE)
  );
};

export const useIsShowAssessmentTable = () => {
  const permissions = useFraudPermissionQuery();

  return permissions.includes(FRAUD_PERMISSION.ASSESSMENT_EXCLUSIONS_READ);
};

export const useIsEnableAssessmentWrite = () => {
  const permissions = useFraudPermissionQuery();

  return permissions.includes(FRAUD_PERMISSION.ASSESSMENT_EXCLUSIONS_WRITE);
};

export const useCanFlagFraudPayment = () => {
  const permissions = useFraudPermissionQuery();

  return permissions.includes(FRAUD_PERMISSION.PAYMENT_FRAUD_FLAGS_WRITE);
};
