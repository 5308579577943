import cx from 'classnames';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import axios from '../../axios';
import { setUserId } from '../../store/features/userSlice';
import ActivationForm from './ActivationForm';
import FailedLogin from './FailedLogin';
import LoginForm from './LoginForm';
import styles from './LoginPage.module.scss';
import SuccessLogin from './SuccessLogin';

const SUCCESS_MESSAGE_WAIT = 2 * 1000;

const MSGS: { [key: string]: string } = {
  PIN_ATTEMPT_LIMIT_EXCEEDED_MSG:
    '認証コードの入力回数上限を超えたため、アカウントがロックされました、時間をおいてから再度お試しください。',
  SESSION_LIMIT_EXCEEDED_MSG:
    '入力回数上限を超えたため、アカウントがロックされました、時間をおいてから再度お試しください。',
  SESSION_NOT_FOUND_MSG: '時間をおいてから再度お試しください。',
  UNEXPECTED_ERROR_MSG: '予期せぬエラーが発生しました。',
};

const LoginPage = () => {
  const dispatch = useDispatch();
  const [loginState, setLoginState] = useState('logged-out');
  // logged-out -> before-active -> logged-in
  //                              > failed (ex: attempt limit exceeded)

  const [pin, setPin] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginFailed, setLoginFailed] = useState('');
  const [activationFailed, setActivationFailed] = useState('');
  const [processFailed, setProcessFailed] = useState('');

  let successMessageTimer: any = null;

  const activeCallback = () => {
    if (successMessageTimer) {
      clearTimeout(successMessageTimer);
    }

    dispatch(setUserId(email));
  };

  const onLogin = (event: any) => {
    event.preventDefault();

    axios
      .post(`/auth/session/mfa`, {
        email,
        password,
      })
      .then(() => {
        setLoginState('before-active');
      })
      .catch((err) => {
        console.log({ err });

        const status = err.response.status;
        const errorData = err.response.data;
        const errorCode = errorData.code || '';
        const errorString =
          typeof errorData === 'string' ? errorData : errorData.message;

        if (errorCode === 'TooManySessions') {
          setProcessFailed('SESSION_LIMIT_EXCEEDED');
          setLoginState('failed');
        } else if (
          (status === 401 && errorString.indexOf('MemberCannotLogin') >= 0) ||
          errorCode === 'MemberCannotLogin'
        ) {
          setProcessFailed('PIN_ATTEMPT_LIMIT_EXCEEDED');
          setLoginState('failed');
        } else if (
          errorCode === 'InvalidPasswordOrUsername' ||
          errorCode === 'MemberNotFound'
        ) {
          setLoginFailed('InvalidPasswordOrUsername');
        } else {
          setLoginFailed('InvalidPasswordOrUsername');
        }
      });
  };

  const resendPin = () => {
    axios
      .post(`/auth/session/mfa`, {
        email,
        password,
      })
      .catch((err) => {
        console.log({ err });

        setProcessFailed('UNEXPECTED_ERROR');
      });
  };

  const onActive = (event: any) => {
    event.preventDefault();

    if (!/\d{4}/.test(pin)) {
      setActivationFailed('InvalidAuthPin');

      return;
    }

    axios
      .post(`/auth/session/mfa/activate`, {
        pin,
      })
      .then(() => {
        successMessageTimer = setTimeout(activeCallback, SUCCESS_MESSAGE_WAIT);

        setPassword(''); // cleanup password for security
        setLoginState('logged-in');
      })
      .catch((err) => {
        console.log({ err });

        const status = err.response.status;
        const errorData = err.response.data;
        const errorCode = errorData.code || '';
        const errorString =
          typeof errorData === 'string' ? errorData : errorData.message;

        setPin('');

        if (status === 404 || errorString.indexOf('SessionNotFound') >= 0) {
          setProcessFailed('SESSION_NOT_FOUND');
          setLoginState('failed');
        } else if (errorCode === 'TooManySessions') {
          setProcessFailed('SESSION_LIMIT_EXCEEDED');
          setLoginState('failed');
        } else if (errorCode === 'AuthPinAttemptLimitExceeded') {
          setProcessFailed('PIN_ATTEMPT_LIMIT_EXCEEDED');
          setLoginState('failed');
        } else if (errorCode === 'InvalidAuthPin') {
          setActivationFailed('InvalidAuthPin');
        } else {
          setActivationFailed('InvalidAuthPin');
        }
      });
  };

  return (
    <div
      className={cx(
        styles['login-box'],
        loginFailed ? styles['has-error'] : ''
      )}
      {...(loginState === 'logged-in'
        ? {
            role: 'button',
            tabIndex: 0,
            onClick: activeCallback,
          }
        : {})}
      {...(loginState === 'failed'
        ? {
            role: 'button',
            tabIndex: 0,
            onClick: () => {
              setLoginState('logged-out');
            },
          }
        : {})}
    >
      {loginState !== 'logged-in' && (
        <h1>
          <img src={require('../../assets/logo.svg')} alt='Paidy' width={118} />
        </h1>
      )}
      {loginState === 'logged-out' && (
        <LoginForm
          loginFailed={loginFailed}
          email={email}
          password={password}
          setLoginFailed={setLoginFailed}
          setEmail={setEmail}
          setPassword={setPassword}
          onLogin={onLogin}
        />
      )}
      {loginState === 'before-active' && (
        <ActivationForm
          activationFailed={activationFailed}
          setActivationFailed={setActivationFailed}
          pin={pin}
          setPin={setPin}
          resendPin={resendPin}
          onActive={onActive}
        />
      )}
      {loginState === 'logged-in' && <SuccessLogin />}
      {loginState === 'failed' && (
        <FailedLogin message={MSGS[`${processFailed}_MSG`]} />
      )}
    </div>
  );
};

export default LoginPage;
