import React, { SyntheticEvent, useEffect, useState } from 'react';
import axios from '../../axios';
import { onError, showNotification, validateSMS } from '../../utils';
import Modal from '../Modal/Modal';
import modalStyles from '../Modal/Modal.module.scss';
import styles from './AccountPage.module.scss';

type SMSModalProps = {
  isOpen: boolean;
  setIsOpen: Function;
  consumerId: string;
  phone: string;
};

const SMSModal = ({ isOpen, setIsOpen, consumerId, phone }: SMSModalProps) => {
  const [sms, setSMS] = useState('');
  const [formError, setFormError] = useState('');

  useEffect(() => {
    setFormError('');
  }, [isOpen]);

  const onSubmit = async (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();

    const errorMsg = validateSMS(sms);

    if (errorMsg) {
      setFormError(errorMsg);

      return;
    }

    const query = `
      {
        sendSms(consumerId: "${consumerId}", smsText: "${sms}")
      }
    `;

    try {
      await axios.post(`/graphql`, {
        operationName: null,
        query,
        variables: {},
      });

      showNotification('SMS sent successfully', 'success');
      setIsOpen(false);
      setSMS('');
    } catch (error) {
      onError(error);
      showNotification('Something went wrong, please try again', 'error');
    }
  };

  return (
    <Modal isOpen={isOpen} data-testid='modal'>
      <button
        data-testid='close_btn'
        onClick={() => setIsOpen(false)}
        className={modalStyles['close-button']}
      >
        <img
          src={require('../../assets/icon-close-modal.svg')}
          alt='close'
          width='24'
          height='24'
        />
      </button>
      <h2>SMSを送る</h2>
      <form onSubmit={onSubmit} data-testid='form'>
        <fieldset>
          <label>電話番号: {phone}</label>
          <textarea
            rows={3}
            placeholder='SMS本文を入力してください。'
            value={sms}
            onChange={(event) => setSMS(event.currentTarget.value)}
          />
          <span className={styles['word-count']}>
            {`${sms.length}/`}
            <em>128文字</em>
          </span>
          {formError && <p className={modalStyles.error}>{formError}</p>}
        </fieldset>
        <div className={modalStyles.actions}>
          <input type='submit' className='btn' value='送信' />
        </div>
      </form>
    </Modal>
  );
};

export default SMSModal;
