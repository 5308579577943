import { useQuery } from '@apollo/react-hooks';
import React, { ClipboardEvent, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { ConsumerPayment } from '../../../types/payment';
import { pagerize } from '../../../utils';
import {
  FRAUD_PERMISSION,
  useCanFlagFraudPayment,
} from '../../../utils/hooks/use-fraud-permission';
import Pagination from '../../Pagination/Pagination';
import { PermissionChecker } from '../../PermissionChecker/PermissionChecker';
import { UnmountableCustomModalProvider } from '../PlusDetail/UnmountableCustomModal';
import FlagPaymentFraudButton from './FlagPaymentFraudButton';
import FlagPaymentFraudModal from './FlagPaymentFraudModal';
import styles from './FraudPayments.module.scss';
import FraudPaymentsItem from './FraudPaymentsItem';
import {
  getPaymentActiveFraudFlags,
  getPaymentFraudFlaggingReasons,
  getPaymentFraudUnflaggingReasons,
  PaymentActiveFraudFlag,
  PaymentFraudFlaggingReason,
} from './gql-query';
import { FilterItem, SearchInput } from './SearchInput';
import UnflagPaymentFraudModal from './UnflagPaymentFraudModal';

const PER_PAGE = 100;

const usePaymentFraudFlags = () => {
  const { consumerId } = useParams() as { consumerId: string };
  const { data, loading, error } = useQuery(getPaymentActiveFraudFlags, {
    variables: { consumerId },
  });

  const paymentsFraudFlags: PaymentActiveFraudFlag[] =
    data?.consumerPaymentsActiveFraudFlags?.paymentsFraudFlags;

  return { paymentsFraudFlags, loading, error };
};

interface FraudPaymentsProps {
  payments: ConsumerPayment[];
}

const useFlaggingReasons = () => {
  const { data, loading, error } = useQuery(getPaymentFraudFlaggingReasons);

  const flaggingReasons: PaymentFraudFlaggingReason[] =
    data?.paymentFraudFlaggingReasons?.flaggingReasons;

  return { flaggingReasons, loading, error };
};

const useUnflaggingReasons = () => {
  const { data, loading, error } = useQuery(getPaymentFraudUnflaggingReasons);

  const unflaggingReasons =
    data?.paymentFraudUnflaggingReasons?.unflaggingReasons;

  return { unflaggingReasons, loading, error };
};

// Extract the payment Ids from the text.
// e.g. we get the following text:
// pay_ZB0XpCoAACkAR5mL,,, pay_YN6dbDwAADwANW1T,.,pay_YN6db.,, amz_XudoGjsAACYAyVd_[][][][]pay_ZB0XpCoAACkAR5mL
// we return ['pay_ZB0XpCoAACkAR5mL', 'pay_YN6dbDwAADwANW1T', 'amz_XudoGjsAACYAyVd_']
// The validator of the payment Id can be found here:
// https://github.com/paidy/paidy-core/blob/9ab807371ef7437fb50492113e07344d3fae9756/core/payments/src/main/scala/com/paidy/payments/api/internal/utils/PathValidation.scala#L5
const extractPaymentIds = (text: string): string[] => {
  const result = text.match(/((pay|amz)_[a-zA-Z0-9_-]{16})/g);
  if (result) {
    return result.filter(
      (value, index, array) => array.indexOf(value) === index
    );
  }
  return [];
};

const FraudPayments = () => {
  const { payments } = useOutletContext<FraudPaymentsProps>();
  const [page, setPage] = useState(0);
  const [selectedPayments, setSelectedPayments] = useState<ConsumerPayment[]>(
    []
  );
  const [filteredPaymentIds, setFilteredPaymentIds] = useState<FilterItem[]>(
    []
  );
  const [allPayments, setAllPayments] = useState<ConsumerPayment[]>(payments);

  const { paymentsFraudFlags = [] } = usePaymentFraudFlags();
  const canFlagFraudPayment = useCanFlagFraudPayment();
  const { flaggingReasons = [] } = useFlaggingReasons();
  const { unflaggingReasons = [] } = useUnflaggingReasons();

  const pageCount = allPayments ? Math.ceil(allPayments.length / PER_PAGE) : 0;
  const pagedPayments = pagerize({
    entries: allPayments,
    page,
    perPage: PER_PAGE,
  });

  const gotoPage = (p: number) => setPage(p);

  const isAllSelected = pagedPayments.every((p) =>
    selectedPayments.find((v) => v.id === p.id)
  );
  const enableFlagButton =
    selectedPayments.length > 0 &&
    selectedPayments.every(
      (p) => !paymentsFraudFlags.find((flag) => flag.paymentId === p.id)
    );
  const enableUnflagButton =
    selectedPayments.length > 0 &&
    selectedPayments.every((p) =>
      paymentsFraudFlags.find((flag) => flag.paymentId === p.id)
    );

  const selectedPaymentFraudFlags = paymentsFraudFlags.filter((flag) =>
    selectedPayments.find((p) => p.id === flag.paymentId)
  );

  const onSelectAllPayments = () => {
    const paymentsNotIncluded = selectedPayments.filter(
      (v) => !pagedPayments.find((p) => p.id === v.id)
    );
    if (isAllSelected) {
      setSelectedPayments([...paymentsNotIncluded]);
    } else {
      setSelectedPayments([...paymentsNotIncluded, ...pagedPayments]);
    }
  };

  const onSelectPayment = (payment: ConsumerPayment) => {
    const isSelected = selectedPayments.find((v) => v.id === payment.id);
    if (isSelected) {
      setSelectedPayments([
        ...selectedPayments.filter((v) => v.id !== payment.id),
      ]);
    } else {
      setSelectedPayments([...selectedPayments, payment]);
    }
  };

  const setPaymentsWithFilters = (filters: FilterItem[]) => {
    const filteredPayments = payments.filter((payment) =>
      filters.some((filter) => payment.id === filter.value)
    );
    setFilteredPaymentIds(
      filters.map((filter) => ({
        value: filter.value,
        matched: filteredPayments.some((p) => p.id === filter.value),
      }))
    );
    setAllPayments(filteredPayments);
  };

  const onFilterPasted = (event: ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();

    const text = event.clipboardData.getData('text/plain');
    if (text) {
      const paymentIds = extractPaymentIds(text);
      if (paymentIds.length > 0) {
        setFilteredPaymentIds(
          paymentIds.map((value) => ({ value, matched: true }))
        );
      }
    }
  };

  const onFilterChange = (filters: FilterItem[]) => {
    setPage(0);
    if (filters.length > 0) {
      setPaymentsWithFilters(filters);
    } else if (allPayments.length !== payments.length) {
      setFilteredPaymentIds(filters);
      setAllPayments(payments);
    }
  };

  return (
    <>
      <div className={styles['fraud-payments-table__header']}>
        <PermissionChecker
          permissions={[FRAUD_PERMISSION.PAYMENT_FRAUD_FLAGS_WRITE]}
          placeholder={<div />}
        >
          <div className={styles['fraud-payments-table__header--btn-group']}>
            <UnmountableCustomModalProvider>
              <FlagPaymentFraudButton
                disabled={!enableFlagButton}
                text='Fraudフラグ追加'
                className='btn primary'
              >
                <FlagPaymentFraudModal
                  flaggingReasons={flaggingReasons}
                  payments={selectedPayments}
                  onCompleted={() => setSelectedPayments([])}
                />
              </FlagPaymentFraudButton>
            </UnmountableCustomModalProvider>

            <UnmountableCustomModalProvider>
              <FlagPaymentFraudButton
                disabled={!enableUnflagButton}
                text='Fraudフラグ解除'
                className='btn primary'
              >
                <UnflagPaymentFraudModal
                  unflaggingReasons={unflaggingReasons}
                  paymentFraudFlags={selectedPaymentFraudFlags}
                  onCompleted={() => setSelectedPayments([])}
                />
              </FlagPaymentFraudButton>
            </UnmountableCustomModalProvider>
          </div>
        </PermissionChecker>
        {pageCount > 1 && (
          <Pagination
            pageCount={pageCount}
            page={page}
            gotoPage={gotoPage}
            isTop
          />
        )}
        <div className={styles['fraud-payments-table__header--search']}>
          <SearchInput
            onPaste={onFilterPasted}
            onFilterChange={onFilterChange}
            initialValue={filteredPaymentIds}
            placeholder='ペイメントIDs'
          />
        </div>
      </div>

      <div className='table'>
        <table>
          <thead>
            <tr>
              <th>
                <input
                  type='checkbox'
                  disabled={!canFlagFraudPayment || pagedPayments.length <= 0}
                  aria-label='select all payments'
                  checked={isAllSelected}
                  onChange={onSelectAllPayments}
                />
              </th>
              <th>ペイメントID</th>
              <th>氏名</th>
              <th>住所</th>
              <th className='align-right'>金額</th>
              <th>作成日</th>
              <th>ステータス</th>
              <th>キャプチャー金額</th>
              <th>リファンド</th>
              <th>Fraud</th>
            </tr>
          </thead>
          <tbody>
            {pagedPayments.length > 0 ? (
              pagedPayments.map((payment) => (
                <FraudPaymentsItem
                  key={payment.id}
                  payment={payment}
                  disabled={!canFlagFraudPayment}
                  isChecked={selectedPayments.some((v) => v.id === payment.id)}
                  isFraud={paymentsFraudFlags.some(
                    (v) => v.paymentId === payment.id
                  )}
                  onChange={() => onSelectPayment(payment)}
                />
              ))
            ) : (
              <tr>
                <td
                  colSpan={10}
                  className={styles['fraud-payments-table__empty-row']}
                >
                  Fraudペイメントがありません
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default FraudPayments;
