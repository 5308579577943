import React from 'react';
import { MAX_NUM_INSTALLMENT_PLAN_ID } from '../../constants';
import { InstallmentPlanType } from '../../types/installmentPlan';
import CancellationResult from './CancellationResult';
import styles from './InstallmentPlanCancelPage.module.scss';

type InstallmentPlanTableProps = {
  ids: string[];
  isProcessing: boolean;
  setIsProcessing: (arg: boolean) => void;
};

type InstallmentPlanRowsProps = {
  isProcessing: boolean;
  installmentPlanGroups: InstallmentPlanType[][];
};

// Devide Installment Plans into groups of MAX_NUM_INSTALLMENT_PLAN_ID
const devideIntoGroups = (ids: InstallmentPlanType[]) => {
  let group: InstallmentPlanType[] = [];
  const groupedIds: InstallmentPlanType[][] = [];

  ids.forEach((id: InstallmentPlanType, index: number) => {
    group.push(id);

    if (
      group.length === MAX_NUM_INSTALLMENT_PLAN_ID ||
      index === ids.length - 1
    ) {
      groupedIds.push(group);
      group = [];
    }
  });

  return groupedIds;
};

const removeDuplicates = (ids: string[]) => {
  const uniqueIds: string[] = [];
  const duplicates: string[] = [];

  ids.forEach((id) => {
    if (!uniqueIds.includes(id)) {
      uniqueIds.push(id);
    } else {
      duplicates.push(id);
    }
  });

  return { uniqueIds, duplicates: [...new Set(duplicates)] };
};

const convertToInstallmentPlan = (ids: string[]) => {
  return ids.map((id: string, index: number) => ({
    order: index + 1,
    id: id,
    status: 'N/A',
    description: 'N/A',
  }));
};

const InstallmentPlanRows = ({
  isProcessing,
  installmentPlanGroups,
}: InstallmentPlanRowsProps) => {
  if (isProcessing) {
    return (
      <>
        {installmentPlanGroups.map(
          (group: InstallmentPlanType[], index: number) => (
            <CancellationResult
              key={Date.now().toString()}
              installmentPlanGroup={group}
              groupIndex={index}
            />
          )
        )}
      </>
    );
  }

  return (
    <>
      {installmentPlanGroups.map((group: InstallmentPlanType[]) =>
        group.map((plan: InstallmentPlanType) => (
          <tr key={plan.id}>
            <td>{plan.order}</td>
            <td>{plan.id}</td>
            <td>{plan.status}</td>
            <td>{plan.description}</td>
          </tr>
        ))
      )}
    </>
  );
};

const InstallmentPlanTable = ({
  ids,
  isProcessing,
  setIsProcessing,
}: InstallmentPlanTableProps) => {
  const { uniqueIds, duplicates } = removeDuplicates(ids);
  const installmentPlans: InstallmentPlanType[] =
    convertToInstallmentPlan(uniqueIds);
  const installmentPlanGroups = devideIntoGroups(installmentPlans);

  return (
    <>
      {/* Cancle Button  */}
      <div className={styles['btn-group']}>
        {!isProcessing && (
          <button
            className={'btn'}
            onClick={() => setIsProcessing(true)}
            data-testid={'cancel-btn'}
          >
            フラグ
          </button>
        )}
      </div>

      {/* Duplicate Error */}
      {duplicates.length > 0 && (
        <div className={styles['duplicate-error']}>
          There are duplicate id(s) in the csv file (
          <span>{duplicates.join(', ')}</span>). The duplicate(s) have been
          removed from the table.
        </div>
      )}

      {/* InstallmentPlanTable */}
      <div className='table'>
        <table>
          <thead>
            <tr>
              <th>No.</th>
              <th>Installment Plan Id</th>
              <th>Status</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <InstallmentPlanRows
              isProcessing={isProcessing}
              installmentPlanGroups={installmentPlanGroups}
            />
          </tbody>
        </table>
      </div>
    </>
  );
};

export default InstallmentPlanTable;
