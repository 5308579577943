import React from 'react';
import { Link, useOutletContext, useParams } from 'react-router-dom';

interface Consumer {
  dateOfBirth: string;
  email: string;
  kanaName: string;
  kanjiName: string;
  phone: string;
  address: {
    zip: string;
    state: string;
    city: string;
    line1: string;
    line2: string;
  };
}

const Details = () => {
  const { consumerId } = useParams() as { consumerId: string };
  const { consumer } = useOutletContext() as {
    consumer: Consumer;
  };

  if (!consumer) {
    return <div className='na-box' />;
  }

  const { dateOfBirth, email, kanaName, kanjiName, phone, address } = consumer;

  return (
    <>
      <div className='table'>
        <table>
          <thead>
            <tr>
              <th>氏名（カナ）</th>
              <th>生年月日</th>
              <th>連絡先</th>
              <th>住所</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Link to={`/accounts/${consumerId}`}>
                  {!kanjiName && !kanaName ? (
                    'N/A'
                  ) : (
                    <>
                      {kanjiName}
                      <br />
                      {kanaName}
                    </>
                  )}
                </Link>
              </td>
              <td>{dateOfBirth || 'Empty'}</td>
              <td>
                {email}
                <br />
                {phone}
              </td>
              <td>
                {address &&
                  `${address.zip || ''} ${address.state || ''} ${
                    address.city || ''
                  } ${address.line2 || ''} ${address.line1 || ''}`}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Details;
