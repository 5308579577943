import gql from 'graphql-tag';

export default gql`
  query fraudManagementPermissions {
    fraudManagementPermissions {
      permissions {
        value
      }
    }
  }
`;
