import React from 'react';
import styles from './FraudFlagLogs.module.scss';

export const NoPermissionViewLog = () => {
  return (
    <tr>
      <td colSpan={3} className={styles['fraud-table__empty-row']}>
        Fraudログ機能にアクセスする権限がありません
      </td>
    </tr>
  );
};
