import { useQuery } from '@apollo/react-hooks';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { NUM_ITEMS_PER_PAGE } from '../../../constants';
import { getTimeString, pagerize } from '../../../utils';
import Pagination from '../../Pagination/Pagination';
import {
  UnmountableCustomModalProvider,
  UnmountableModalWrapper,
  useUnmountableCustomModalContext,
} from '../PlusDetail/UnmountableCustomModal';
import CreateFraudFlagModal from './CreateFraudFlagModal';
import DeleteFraudFlagModal from './DeleteFraudFlagModal';
import styles from './Fraud.module.scss';
import { getFlaggingReasons, getFraudFlags } from './gql-query';

type FlagRowProps = {
  id: string;
  flaggedFor: string;
  flaggedBy: string;
  flaggedAt: number;
};

type DeleteFlagButtonProps = {
  flagId: string;
};

const useFraudFlags = () => {
  const { consumerId } = useParams() as { consumerId: string };

  const {
    data = {},
    loading,
    error,
  } = useQuery(getFraudFlags, {
    variables: { consumerId },
  });

  const fraudFlags = data?.activeFraudFlagsByConsumerId || [];

  return { fraudFlags, loading, error };
};

const useFlaggingReasons = () => {
  const { data = {}, loading, error } = useQuery(getFlaggingReasons);

  const { flaggingReasons = [] } = data;

  return { flaggingReasons, loading, error };
};

const CreateFlagButton = () => {
  const { onOpen } = useUnmountableCustomModalContext();
  const { flaggingReasons = [] } = useFlaggingReasons();

  return (
    <>
      <button className={`btn ${styles['btn-primary']}`} onClick={onOpen}>
        フラグ追加
      </button>
      <UnmountableModalWrapper className={styles['fraud-modal']}>
        <CreateFraudFlagModal flaggingReasons={flaggingReasons} />
      </UnmountableModalWrapper>
    </>
  );
};

const DeleteFlagButton = ({ flagId }: DeleteFlagButtonProps) => {
  const { onOpen } = useUnmountableCustomModalContext();

  return (
    <>
      <button className={`btn ${styles['btn-primary']}`} onClick={onOpen}>
        フラグ解除
      </button>
      <UnmountableModalWrapper className={styles['fraud-modal']}>
        <DeleteFraudFlagModal flagId={flagId} />
      </UnmountableModalWrapper>
    </>
  );
};

const FlagRow = ({ id, flaggedFor, flaggedBy, flaggedAt }: FlagRowProps) => {
  return (
    <tr>
      <td>{id}</td>
      <td>{getTimeString(new Date(flaggedAt))}</td>
      <td>{flaggedFor}</td>
      <td>{flaggedBy}</td>
      <td className={`${styles['fraud-table__btn-column']}`}>
        <UnmountableCustomModalProvider>
          <DeleteFlagButton flagId={id} />
        </UnmountableCustomModalProvider>
      </td>
    </tr>
  );
};

const FraudFlagsTable = () => {
  const { fraudFlags = [] } = useFraudFlags();

  const [page, setPage] = useState(0);

  const pageCount = fraudFlags
    ? Math.ceil(fraudFlags.length / NUM_ITEMS_PER_PAGE)
    : 0;

  const pagedFraudFlags = pagerize({
    entries: fraudFlags,
    page,
    perPage: NUM_ITEMS_PER_PAGE,
  });

  const gotoPage = (p: number) => {
    setPage(p);
  };

  useEffect(() => {
    // page starts from 0
    // pageCount starts from 1
    if (page != 0 && page > pageCount - 1) {
      setPage(pageCount - 1);
    }
  }, [fraudFlags]);

  return (
    <div className={`table ${styles['fraud-table']}`}>
      <span className={styles['fraud-table__heading']}>
        <h1>Fraudフラグ設定</h1>
        <UnmountableCustomModalProvider>
          <CreateFlagButton />
        </UnmountableCustomModalProvider>
      </span>
      <table>
        <thead>
          <tr>
            <th>フラグID</th>
            <th>フラグ作成日</th>
            <th>フラグ作成理由</th>
            <th>作成者</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {pagedFraudFlags.length ? (
            pagedFraudFlags.map((flag) => (
              <FlagRow
                key={flag.id}
                id={flag.id}
                flaggedFor={flag.flaggedFor}
                flaggedBy={flag.flaggedBy}
                flaggedAt={flag.flaggedAt}
              />
            ))
          ) : (
            <tr>
              <td colSpan={5} className={styles['fraud-table__empty-row']}>
                Fraudフラグがありません
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {pageCount > 1 && (
        <Pagination pageCount={pageCount} page={page} gotoPage={gotoPage} />
      )}
    </div>
  );
};

export default FraudFlagsTable;
