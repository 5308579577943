import React from 'react';
import styles from './InstallmentPlanCancelPage.module.scss';

type CSVSelectorProps = {
  onChange: (data: string[]) => void;
};

const CSVSelector = ({ onChange }: CSVSelectorProps) => {
  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      try {
        const file = e.target.files[0];

        const reader = new FileReader();

        reader.onload = async (e) => {
          const text: string = (e?.target?.result as string) || '';
          const ids: string[] = text.match(/isp_(.){16}/g) || []; // Installment Plan ID format: isp_XXXXXXXXXXXXXXXX

          onChange(ids);
        };

        reader.readAsText(file);
      } catch (error) {
        console.log(error);
      }
    } else {
      onChange([]);
    }
  };

  return (
    <div className={styles['csv-selector']}>
      <input
        type='file'
        accept='.csv'
        onChange={handleFileChange}
        data-testid='file-input'
      />
    </div>
  );
};

export default CSVSelector;
