import { useQuery } from '@apollo/react-hooks';
import cx from 'classnames';
import idx from 'idx';
import React from 'react';
import { useParams } from 'react-router-dom';
import { currencyFormatter, getTimeString, onError } from '../../utils';
import Card from '../Card/Card';
import LoadingPartial from '../Loading/LoadingPartial';
import styles from './CombiniPaymentPage.module.scss';
import gqlQuery from './gql-query';

type CombiniPaymentPageProps = { combiniPaymentId: string };

const CombiniPaymentPage = () => {
  const { combiniPaymentId } = useParams<CombiniPaymentPageProps>();
  const { loading, data } = useQuery(gqlQuery, {
    onError,
    variables: { combiniPaymentId },
  });

  if (loading) {
    return <LoadingPartial />;
  }

  if (!data || !data.combinipayment) {
    return <div className='na-box' />;
  }

  const payment = data.combinipayment;

  const buyerKanjiName = idx(payment, (_) => _.buyer.name1);
  const buyerKanaName = idx(payment, (_) => _.buyer.name2);

  return (
    <>
      <Card>
        <h3 className={styles.title}>コンビニ払い</h3>
        <h4>支払い詳細</h4>
        <div className={cx('table striped', styles.details)}>
          <table>
            <tbody>
              <tr>
                <th className={styles.short}>コンビニペイメントID</th>
                <td>{combiniPaymentId}</td>
              </tr>
              <tr>
                <th>ステータス</th>
                <td>
                  <span
                    className={`tag ${
                      payment.status ? payment.status.toLowerCase() : ''
                    }`}
                  >
                    {payment.status}
                  </span>
                </td>
              </tr>
              <tr>
                <th>オーダーリファレンス</th>
                <td>{payment.orderRef}</td>
              </tr>
              <tr>
                <th>金額</th>
                <td>{payment.amount}</td>
              </tr>
              <tr>
                <th>支払手数料</th>
                <td>{currencyFormatter.format(payment.settlementFeeAmount)}</td>
              </tr>
              <tr>
                <th>マーチャント</th>
                <td>Paidy</td>
              </tr>
              <tr>
                <th>購入者名</th>
                <td>
                  {buyerKanjiName || 'N/A'}{' '}
                  {buyerKanaName && `(${buyerKanaName})`}
                </td>
              </tr>
              <tr>
                <th>購入者 メールアドレス</th>
                <td>{idx(payment, (_) => _.buyer.email) || 'N/A'}</td>
              </tr>
              <tr>
                <th>購入者 電話番号</th>
                <td>{idx(payment, (_) => _.buyer.phone) || 'N/A'}</td>
              </tr>
              <tr>
                <th>作成日</th>
                <td>{getTimeString(new Date(payment.createdAt))}</td>
              </tr>
              <tr>
                <th>更新日</th>
                <td>{getTimeString(new Date(payment.updatedAt))}</td>
              </tr>
              <tr>
                <th>期限日</th>
                <td>{getTimeString(new Date(payment.expiresAt))}</td>
              </tr>
              <tr>
                <th>テスト</th>
                <td>{payment.test ? 'Yes' : 'No'}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <h4>プロバイダー</h4>
        <div className='table striped'>
          <table>
            <tbody>
              <tr>
                <th className={styles.short}>プロバイダー名</th>
                <td>{idx(payment, (_) => _.provider.providerName) || 'N/A'}</td>
              </tr>
              <tr>
                <th>プロバイダーリファレンス</th>
                <td>{idx(payment, (_) => _.provider.providerRef) || 'N/A'}</td>
              </tr>
              <tr>
                <th>店舗：すべて</th>
                <td>
                  Consumer Code:{' '}
                  {idx(payment, (_) => _.provider.consumerCode) || 'N/A'}
                  <br />
                  Merchant Code:{' '}
                  {idx(payment, (_) => _.provider.merchantCode) || 'N/A'}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Card>
    </>
  );
};

export default CombiniPaymentPage;
