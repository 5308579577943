import React, { FC } from 'react';
import { getTimeString } from '../../../utils';
import { PaymentFraudFlag } from '../gql-query';
import styles from './FraudFlagLogs.module.scss';

interface FraudFlagDetailProps {
  fraudFlags: PaymentFraudFlag[];
}

const FraudFlagDetail: FC<FraudFlagDetailProps> = ({ fraudFlags = [] }) => {
  return (
    <div className={`table ${styles['fraud-table']}`}>
      <span className={styles['fraud-table__heading']}>
        <h1>Fraudフラグ詳細</h1>
      </span>
      <table>
        <thead>
          <tr>
            <th>作成日</th>
            <th>作成者</th>
            <th>作成理由</th>
            <th>請求対応</th>
          </tr>
        </thead>
        <tbody>
          {fraudFlags.length > 0 ? (
            fraudFlags.map((fraudFlag) => (
              <tr key={fraudFlag.id}>
                <td className='no-wrap'>
                  {getTimeString(new Date(fraudFlag.flaggedAt))}
                </td>
                <td>{fraudFlag.flaggedBy}</td>
                <td>
                  {typeof fraudFlag.flaggedFor === 'string'
                    ? fraudFlag.flaggedFor
                    : fraudFlag.flaggedFor.description}
                </td>
                <td>
                  {fraudFlag.excludeFromBill
                    ? '請求停止'
                    : '請求書から除外しない'}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={4} className={styles['fraud-table__empty-row']}>
                Fraudフラグがありません
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default FraudFlagDetail;
