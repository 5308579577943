import { useMutation } from '@apollo/react-hooks';
import React, { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ConsumerPayment } from '../../../types/payment';
import modalStyles from '../../Modal/Modal.module.scss';
import { ErrorMessage, LoadingMessage } from '../PlusDetail/ModalMessages';
import { useUnmountableCustomModalContext } from '../PlusDetail/UnmountableCustomModal';
import styles from './FraudPayments.module.scss';
import {
  flagFraudPayments,
  getPaymentActiveFraudFlags,
  PaymentFraudFlaggingReason,
} from './gql-query';

export const ExceptFromBill = 'except_from_bill';
export const NotExceptFromBill = 'not_except_from_bill';

const useFlagFraudPayments = (
  consumerId: string,
  reasonId: string,
  excludeFromBill: boolean,
  paymentsToFlag: { paymentId: string; merchantId: string }[],
  onCompleted: () => void
) => {
  return useMutation(flagFraudPayments, {
    variables: {
      consumerId,
      flaggedFor: reasonId,
      excludeFromBill,
      paymentsToFlag,
    },
    onCompleted,
    refetchQueries: () => [
      {
        query: getPaymentActiveFraudFlags,
        variables: { consumerId },
      },
    ],
  });
};

interface FlagPaymentFraudModalProps {
  flaggingReasons: PaymentFraudFlaggingReason[];
  payments: ConsumerPayment[];
  onCompleted: () => void;
}

const FlagPaymentFraudModal: FC<FlagPaymentFraudModalProps> = ({
  flaggingReasons,
  payments,
  onCompleted,
}) => {
  const { onCancel, onConfirm } = useUnmountableCustomModalContext();
  const { consumerId } = useParams() as { consumerId: string };
  const [reasonId, setReasonId] = useState('');
  const [exceptFromBill, setExceptFromBill] =
    useState<string>(NotExceptFromBill);
  const [readyToSubmit, setReadyToSubmit] = useState(false);

  const onMutationCompleted = () => {
    onConfirm();
    onCompleted && onCompleted();
  };

  const [mutate, { loading, error }] = useFlagFraudPayments(
    consumerId,
    reasonId,
    exceptFromBill === ExceptFromBill,
    payments.map((p) => ({
      paymentId: p.id,
      merchantId: p.merchantId,
    })),
    onMutationCompleted
  );

  const disabled = loading;

  const getReasonById = (id: string) => {
    const currentReason = flaggingReasons.find((reason) => reason.id === id);

    return currentReason ? currentReason.description : '';
  };

  const onSubmit = () => {
    if (!readyToSubmit) {
      setReadyToSubmit(true);
      return;
    }

    mutate();
  };

  return (
    <>
      <button onClick={onCancel} className={modalStyles['close-button']}>
        <img
          src={require('../../../assets/icon-close-modal.svg')}
          alt='close'
          width='24'
          height='24'
        />
      </button>
      <h2>Fraudフラグ設定する</h2>
      <LoadingMessage loading={loading}></LoadingMessage>
      <ErrorMessage error={error}></ErrorMessage>
      <form onSubmit={(e) => e.preventDefault()}>
        {!readyToSubmit && (
          <>
            <fieldset>
              <label>Fraudフラグ作成理由</label>
              <select
                defaultValue=''
                className={reasonId ? styles['select-active'] : ''}
                onChange={(event) => setReasonId(event.target.value)}
              >
                <option value='' disabled>
                  選択して下さい
                </option>
                {flaggingReasons.map((reason) => (
                  <option key={reason.id} value={reason.id}>
                    {reason.description}
                  </option>
                ))}
              </select>
            </fieldset>
            <fieldset>
              <label>請求書からペイメントを除外しますか？</label>
              <select
                defaultValue={NotExceptFromBill}
                className={exceptFromBill ? styles['select-active'] : ''}
                onChange={(event) => setExceptFromBill(event.target.value)}
              >
                <option value='' disabled>
                  選択して下さい
                </option>
                <option value={ExceptFromBill}>請求停止</option>
                <option value={NotExceptFromBill}>請求書から除外しない</option>
              </select>
            </fieldset>
          </>
        )}

        {readyToSubmit && !error && !loading && (
          <div>
            <p>下記のフラグ作成設定を確認してください。</p>
            <div>
              <p>
                <strong>ペイメント数</strong>
              </p>
              <p>• {payments.length}</p>
            </div>
            <div>
              <p>
                <strong>フラグ詳細</strong>
              </p>
              <p>• 理由：{getReasonById(reasonId)}</p>
              <p>
                •{' '}
                {exceptFromBill === ExceptFromBill
                  ? '請求停止'
                  : '請求書から除外しない'}
              </p>
            </div>
          </div>
        )}

        <div className={`${modalStyles.actions} ${styles['action-group']}`}>
          <button
            disabled={!reasonId || !exceptFromBill || disabled}
            className={`btn red`}
            onClick={onSubmit}
          >
            フラグ
          </button>
          <button
            disabled={disabled}
            className={`btn primary`}
            onClick={onCancel}
          >
            キャンセル
          </button>
        </div>
      </form>
    </>
  );
};

export default FlagPaymentFraudModal;
