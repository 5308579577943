/* eslint-disable no-use-before-define */

import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { labelMap } from '../../../constants';
import { processValue } from './utils';

const fieldsToSkip = [
  '__typename',
  'contractData',
  'consumerId',
  'test',
  'status',
];

type AuthorizationsProps = {
  payment: {
    [key: string]: any;
  };
};

export const Auth = () => {
  const { payment } = useOutletContext() as AuthorizationsProps;

  if (!payment) {
    return <div className='na-box' />;
  }

  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isSpecifiedContinuousServiceMerchant: unusedField,
    ...filteredPayment
  } = payment;
  return (
    <>
      <div className='table'>
        <table>
          <tbody>
            {Object.keys(filteredPayment).map((k) => {
              if (fieldsToSkip.indexOf(k) > -1) {
                return null;
              }

              return (
                <tr key={k}>
                  <th style={{ width: 210 }}>{labelMap[k] || k}</th>
                  <td>{processValue(k, payment[k])}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};
