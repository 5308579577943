import cx from 'classnames';
import React from 'react';
import Modal from 'react-modal';
import styles from './Modal.module.scss';

type CustomModalProps = {
  children: React.ReactNode;
  className?: string;
  isOpen: boolean;
};

if (process.env.NODE_ENV !== 'test') {
  Modal.setAppElement('#root');
}

const CustomModal = ({
  children,
  className = '',
  isOpen,
}: CustomModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      className={cx(styles.modal, className)}
      overlayClassName={styles.overlay}
      ariaHideApp={process.env.NODE_ENV !== 'test'}
    >
      {children}
    </Modal>
  );
};

export default CustomModal;
