import React, { useState } from 'react';
import Card from '../Card/Card';
import CSVSelector from './CSVSelector';
import styles from './InstallmentPlanCancelPage.module.scss';
import InstallmentPlanTable from './InstallmentPlanTable';

const InstallmentPlanCancelPage = () => {
  const [ids, setIds] = useState<string[]>([]);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const handleChooseFile = (ids: string[]) => {
    setIsProcessing(false);
    setIds(ids);
  };

  return (
    <Card>
      <h3>Installment Plans Bulk Cancellation</h3>
      <CSVSelector onChange={handleChooseFile} />

      {ids.length ? (
        <InstallmentPlanTable
          ids={ids}
          isProcessing={isProcessing}
          setIsProcessing={setIsProcessing}
        />
      ) : (
        <div className={styles.error}>No Data</div>
      )}
    </Card>
  );
};

export default InstallmentPlanCancelPage;
