import gql from 'graphql-tag';

export default gql`
  query BillStatement($billStatementId: ID!, $consumerId: ID!) {
    billstatementsWithSettlements(consumerId: $consumerId) {
      billStatement {
        id
        issueDate
        amount
        unpaidAmount
        currency
        dueAt
        paidAt
        status
        lateFeesAmount
      }
      autoDebitSettlement {
        pullAmount
        pullStatus
        resultsProcessedBy
      }
      combiniSettlement {
        combiniPaymentId
        amount
        settlementFeeAmount
        merchantCode
        consumerCode
      }
    }
    paidycode(billStatementId: $billStatementId) {
      value
    }
  }
`;
