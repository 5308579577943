import React from 'react';
import { useOutletContext } from 'react-router-dom';
import styles from '../PaymentPage.module.scss';

type Report = {
  id: string;
  paymentId: string;
  kind: string;
  result: string;
  reason: string;
  metadataJson: string;
};

type AssessmentsProps = {
  reports: Array<Report>;
};

const Assessments = () => {
  const { reports } = useOutletContext() as AssessmentsProps;
  const formatReports = (reports: Array<Report>) => {
    if (!reports) {
      return '';
    }

    let text = 'Assessments\n\n';

    reports.forEach((report: Report) => {
      text += `${report.kind}\n\n`;
      text += `Result: ${report.result}\n`;
      text += `Reason: ${report.reason}\n`;
      text += `${
        JSON.stringify(JSON.parse(report.metadataJson), null, 2) || 'null'
      }\n\n\n\n`;
    });

    return text;
  };

  return (
    <textarea
      className={styles['assessments']}
      disabled
      value={formatReports(reports)}
    />
  );
};

export default Assessments;
