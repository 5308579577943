import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SearchKeywordState {
  value: string;
}

const initialState: SearchKeywordState = {
  value: '',
};

export const searchKeywordSlice = createSlice({
  name: 'searchKeyword',
  initialState,
  reducers: {
    setSearchKeyword: (state, action: PayloadAction<string>) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSearchKeyword } = searchKeywordSlice.actions;

export default searchKeywordSlice.reducer;
