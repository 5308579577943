import React from 'react';
import { currencyFormatter, getTimeString } from '../../../utils';
import Pagination from '../../Pagination/Pagination';
import {
  UnmountableCustomModalProvider,
  UnmountableModalWrapper,
  useUnmountableCustomModalContext,
} from '../PlusDetail/UnmountableCustomModal';
import { CancelContractModal } from './CancelContractModal';
import { HoukatsuDataProvider, useHoukatsuContext } from './HoukatsuContext';

type RevertButtonProps = { id: string; status: string };
type HoukatsuTableProps = { children: React.ReactNode };

export const RevertButton = ({ id, status }: RevertButtonProps) => {
  const { onOpen } = useUnmountableCustomModalContext();

  const disabled = status !== 'active';
  const className = disabled ? 'btn disabled' : 'btn red';

  return (
    <>
      <button className={className} onClick={onOpen} disabled={disabled}>
        一括払いに戻す
      </button>
      <UnmountableModalWrapper>
        <CancelContractModal id={id}></CancelContractModal>
      </UnmountableModalWrapper>
    </>
  );
};

export const DataRows = () => {
  const { contracts } = useHoukatsuContext();

  if (!contracts) return null;
  if (!contracts.length) return null;

  const getTag = (type: string) => {
    if (type === '3-Pay') {
      return <span className={`tag plus-houkatsu`}>3-Pay</span>;
    } else if (type === '6-Pay') {
      return <span className={`tag plus-ekyc`}>6-Pay</span>;
    } else if (type === '12-Pay') {
      return <span className={`tag plus-12pay`}>12-Pay</span>;
    } else {
      return <span className={`tag apple`}>Apple</span>;
    }
  };

  return (
    <tbody>
      {contracts.map((row, i) => (
        <tr key={`${row.id}${i}`}>
          <td>{getTag(row.type)}</td>
          <td>{row.paymentId}</td>
          <td>{row.merchantName}</td>
          <td>{currencyFormatter.format(row.totalCaptureAmount)}</td>
          <td>
            {row.status === 'canceled'
              ? currencyFormatter.format(0)
              : currencyFormatter.format(row.outstandingAmount)}
          </td>
          <td>{currencyFormatter.format(row.refundAmount)}</td>
          <td>{getTimeString(new Date(row.dateConverted))}</td>
          <td>{row.status}</td>
          <td>
            <UnmountableCustomModalProvider>
              <RevertButton id={row.id} status={row.status}></RevertButton>
            </UnmountableCustomModalProvider>
          </td>
        </tr>
      ))}
    </tbody>
  );
};

export const WrappedPagination = () => {
  const { gotoPage, page, pageCount } = useHoukatsuContext();

  if (pageCount < 1) return null;

  return (
    <Pagination pageCount={pageCount} page={page} gotoPage={gotoPage} isTop />
  );
};

export const HoukatsuTable = ({ children }: HoukatsuTableProps) => {
  const { loading } = useHoukatsuContext();

  if (loading) return null;

  return (
    <div className='table'>
      <table>
        <thead>
          <tr>
            <th>分割払いの種類</th>
            <th>ペイメントID</th>
            <th>加盟店</th>
            <th>分割払い総額</th>
            <th>未作成チャージ</th>
            <th>リファンド</th>
            <th>契約日</th>
            <th>ステータス</th>
            <th></th>
          </tr>
        </thead>
        {children}
      </table>
    </div>
  );
};

const HoukatsuDetail = () => {
  return (
    <HoukatsuDataProvider>
      <HoukatsuTable>
        <DataRows></DataRows>
      </HoukatsuTable>
      <WrappedPagination></WrappedPagination>
    </HoukatsuDataProvider>
  );
};

export default HoukatsuDetail;
