/* eslint-disable no-use-before-define */

import React from 'react';

import { getTimeString, currencyFormatter } from '../../../utils';
import { labelMap } from '../../../constants';

import styles from './Auth.module.scss';

export const processValue = (key: string, value: any) => {
  if (key === 'test') {
    return value ? 'Yes' : 'No';
  }

  if (['mortgage', 'maritalStatus'].indexOf(key) > -1) {
    return value ? 'あり' : 'なし';
  }

  if (!value) {
    return 'N/A';
  }

  if (key === 'tags' && value.length > 0) {
    const tagEls = value.map((v, i) => (
      <span key={i} className="tag gray">
        {v}
      </span>
    ));

    return <div className={styles.tags}>{tagEls}</div>;
  }

  if (key.toLowerCase().endsWith('id')) {
    return <span className="bold">{value}</span>;
  }

  if (key === 'gender') {
    return value === 'female' ? '女性' : '男性';
  }

  if (key === 'contractData' && typeof value === 'object') {
    return formatObject(key, value);
  }

  if (key.toLowerCase().endsWith('address') && typeof value === 'object') {
    const { line1, line2, city, state, zip } = value;

    return `${zip || ''} ${state || ''} ${city || ''} ${line2 || ''} ${line1 ||
      ''}`;
  }

  if (
    (key.toLowerCase().endsWith('amount') ||
      key.toLowerCase().endsWith('income')) &&
    typeof value === 'number'
  ) {
    return currencyFormatter.format(value);
  }

  if (['status', 'tier'].indexOf(key) > -1) {
    return <span className={`tag ${value.toLowerCase()}`}>{value}</span>;
  }

  if (typeof value === 'object' && value !== null) {
    if (Object.prototype.hasOwnProperty.call(value, 'length')) {
      return value.length > 0 ? formatArray(key, value) : 'None';
    }

    return formatObject(key, value);
  }

  if (key.endsWith('At') && value) {
    return getTimeString(new Date(value));
  }

  if (key === 'appleOrderNumber') {
    const regex = /(?<=\d)r\d+/;

    return regex.test(value) ? regex.exec(value)[0] : value;
  }

  if (key === 'buyerDataJson') {
    return (
      <pre className={styles['data-buyer-json']}>
        {JSON.stringify(JSON.parse(value), null, 2)}
      </pre>
    );
  }

  return value;
};

export const formatObject = (key: string, value: object) => {
  return Object.keys(value).map(k => {
    if (k === '__typename') {
      return null;
    }

    return (
      <div className={styles.row} key={k}>
        <span className={styles.label}>{labelMap[k] || k}</span>
        <span className={styles.value}>{processValue(k, value[k])}</span>
      </div>
    );
  });
};

export const formatArray = (key: string, arr: Array<any>) =>
  arr.map((item, i) => (
    <div className={styles.box} key={i}>
      {formatObject(key, item)}
    </div>
  ));
