import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import axios from '../../axios';
import { RootState } from '../../store';
import { setSearchKeyword } from '../../store/features/searchKeywordSlice';
import { setUserId } from '../../store/features/userSlice';
import { normalizeSearchKeyword, onError } from '../../utils';
import styles from './Header.module.scss';

const Header = () => {
  const [inputKeyword, setInputKeyword] = useState('');
  const userId = useSelector((state: any) => state.user.userId);
  const searchKeyword = useSelector(
    (state: RootState) => state.searchKeyword.value
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const onLogout = async () => {
    try {
      await axios.delete(`/auth/session2`);

      dispatch(setUserId(''));
    } catch (err) {
      onError(err);
      throw err;
    }
  };

  const onSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const newSearchKeyword = normalizeSearchKeyword(inputKeyword);

      // Do nothing if the search keyword is the same
      if (newSearchKeyword === searchKeyword) {
        return null;
      }

      if (newSearchKeyword !== inputKeyword) {
        setInputKeyword(newSearchKeyword);
      }
      dispatch(setSearchKeyword(newSearchKeyword));

      // Redirect to search page if not already there
      if (!location.pathname.includes('search')) {
        navigate('search/payments');
      }
    }
  };

  if (location.pathname.startsWith('/login')) {
    return null;
  }

  return (
    <div className={styles['header-wrapper']}>
      <header>
        {/* Logo */}
        <Link
          to='search/payments'
          className={styles.logo}
          onClick={() => {
            setInputKeyword('');
            dispatch(setSearchKeyword(''));
          }}
        />

        {/* Search Input */}
        <input
          className={styles['input-search']}
          type='search'
          id='search_submit'
          data-testid='search_submit'
          value={inputKeyword}
          placeholder='ID、IdemiaID、VAN、名前、電話番号またはメールアドレスで検索（最低2字）'
          onChange={(event) => {
            setInputKeyword(event.currentTarget.value);
          }}
          onKeyDown={onSearch}
        />

        {/* InstallmentPlan Cancel and Logout button */}
        <div>
          <span className={styles.account}>{userId}</span>
          <NavLink to='installmentPlanCancel' className={styles['btn-auth']}>
            Installment Plans Cancel
          </NavLink>
          <button
            onClick={onLogout}
            className={styles['btn-auth']}
            data-testid='signout_btn'
          >
            ログアウト
          </button>
        </div>
      </header>
    </div>
  );
};

export default Header;
