import React, { useMemo } from 'react';
import styles from './DataTable.module.scss';

const DataTable = ({
  variant = 'default',
  tableData,
}: {
  variant?: 'default' | 'grey';
  tableData: { columnTitle: string; columnData: string[] }[];
}) => {
  const tableRows = useMemo(() => {
    const rows = [];

    const numberOfRows = Math.max(
      ...tableData.map((column) => column.columnData.length)
    );
    for (let i = 0; i < numberOfRows; i++) {
      rows.push(tableData.map((column) => column.columnData[i]));
    }

    return rows;
  }, [tableData]);

  return (
    <table className={styles.table}>
      <thead data-variant={variant}>
        <tr>
          {tableData.map((column) => (
            <td key={column.columnTitle}>{column.columnTitle}</td>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableRows.map((item, index) => (
          <tr key={index}>
            {item.map((text, currentIndex) => (
              <td key={`${text}${currentIndex}`}>{text}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default DataTable;
