import { useMutation } from '@apollo/react-hooks';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import modalStyles from '../../Modal/Modal.module.scss';
import consumerQuery from '../gql-query';
import { ErrorMessage, LoadingMessage } from '../PlusDetail/ModalMessages';
import { useUnmountableCustomModalContext } from '../PlusDetail/UnmountableCustomModal';
import styles from './Fraud.module.scss';
import { createFraudFlag, getFraudEventLogs, getFraudFlags } from './gql-query';

type FlaggingReasonType = {
  id: string;
  reason: string;
};

type CreateFraudFlagModalProps = {
  flaggingReasons: FlaggingReasonType[];
};

const useCreateFraudFlag = (
  consumerId: string,
  reasonId: string,
  onCompleted: () => void
) => {
  return useMutation(createFraudFlag, {
    variables: { consumerId, flaggedFor: reasonId },
    onCompleted,
    refetchQueries: () => [
      {
        query: getFraudFlags,
        variables: { consumerId },
      },
      {
        query: getFraudEventLogs,
        variables: { consumerId },
      },
      {
        query: consumerQuery,
        variables: { consumerId },
      },
    ],
  });
};

const CreateFraudFlagModal = ({
  flaggingReasons,
}: CreateFraudFlagModalProps) => {
  const { onCancel, onConfirm } = useUnmountableCustomModalContext();
  const { consumerId } = useParams() as { consumerId: string };
  const [reasonId, setReasonId] = useState('');
  const [readyToSubmit, setReadyToSubmit] = useState(false);
  const onCompleted = onConfirm;

  const [mutate, { loading, error }] = useCreateFraudFlag(
    consumerId,
    reasonId,
    onCompleted
  );

  const disabled = loading;

  const getReasonById = (id: string) => {
    const currentReason = flaggingReasons.find((reason) => reason.id === id);

    return currentReason ? currentReason.reason : '';
  };

  const onSubmit = () => {
    if (!readyToSubmit) {
      setReadyToSubmit(true);
      return;
    }

    mutate();
  };

  return (
    <>
      <button onClick={onCancel} className={modalStyles['close-button']}>
        <img
          src={require('../../../assets/icon-close-modal.svg')}
          alt='close'
          width='24'
          height='24'
        />
      </button>
      <h2>Fraudフラグ設定する</h2>
      <LoadingMessage loading={loading}></LoadingMessage>
      <ErrorMessage error={error}></ErrorMessage>
      <form onSubmit={(e) => e.preventDefault()}>
        {!readyToSubmit && (
          <fieldset>
            <label>Fraudフラグ作成理由</label>
            <select
              defaultValue=''
              onChange={(event) => setReasonId(event.target.value)}
              data-testid='select'
            >
              <option value='' disabled>
                選択して下さい
              </option>
              {flaggingReasons.map((reason) => (
                <option key={reason.id} value={reason.id}>
                  {reason.reason}
                </option>
              ))}
            </select>
          </fieldset>
        )}

        {readyToSubmit && !error && !loading && (
          <div>
            <p>下記の設定で顧客アカウントをfraudフラグをしますか?</p>
            <p>• {getReasonById(reasonId)}</p>
            <p>
              Fraudフラグした顧客アカウントはDISABLEDになりますのでご注意ください。
            </p>
          </div>
        )}

        <div className={`${modalStyles.actions} ${styles['action-group']}`}>
          <button
            disabled={!reasonId || disabled}
            className={`btn ${styles['btn-secondary']}`}
            onClick={onSubmit}
            data-testid='submit-btn'
          >
            フラグ
          </button>
          <button
            disabled={disabled}
            className={`btn ${styles['btn-primary']}`}
            onClick={onCancel}
          >
            キャンセル
          </button>
        </div>
      </form>
    </>
  );
};

export default CreateFraudFlagModal;
