import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { currencyFormatter } from '../../../utils';
import { PacDataRows } from './PacDataRows';
import PaidyLinkRow from './PaidyLinkRow';
import PlusApplicationDateRows from './PlusApplicationDateRows';
import ResetKYCStatusModal from './ResetKYCStatusModal';

type PlusDetailProps = {
  plusData: { [key: string]: any };
};

const PlusDetail = () => {
  const { plusData } = useOutletContext() as PlusDetailProps;

  if (!plusData) {
    return null;
  }

  const {
    appleLineOfCreditLimit,
    spendLimit,
    eKycConsumerData: {
      firstNameKanji,
      lastNameKanji,
      firstNameKana,
      lastNameKana,
      birthDate,
      occupation,
      address,
      applicationReason,
    },
  } = plusData;

  const max = spendLimit?.max || 0;
  const selected = spendLimit?.selected || 0;

  const renderAddress = (address: any) => {
    if (!address) {
      return 'N/A';
    }

    const values = Object.values(address);
    const isEmpty = values.every((value) => !value);

    if (isEmpty) {
      return 'N/A';
    }

    return `${address.zip || ''} ${address.state || ''} ${
      address.city || ''
    } ${address.line2 || ''} ${address.line1 || ''}`;
  };

  return (
    <>
      <div className='table'>
        <table>
          <tbody>
            <ResetKYCStatusModal></ResetKYCStatusModal>
            <PlusApplicationDateRows></PlusApplicationDateRows>
            <tr>
              <th>予算の設定</th>
              <td>
                <table>
                  <tbody>
                    <tr>
                      <th>ご利用限度額</th>
                      <td>{currencyFormatter.format(max)}</td>
                    </tr>
                    <tr>
                      <th>今月の予算</th>
                      <td>{currencyFormatter.format(selected)}</td>
                    </tr>
                    <tr>
                      <th>あと払いApple専用限度額</th>
                      <td>
                        {currencyFormatter.format(appleLineOfCreditLimit || 0)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <th>氏名</th>
              <td>
                {lastNameKanji || firstNameKanji
                  ? `${lastNameKanji} ${firstNameKanji}`
                  : 'N/A'}
              </td>
            </tr>
            <tr>
              <th>氏名（カナ）</th>
              <td>
                {lastNameKana || firstNameKana
                  ? `${lastNameKana} ${firstNameKana}`
                  : 'N/A'}
              </td>
            </tr>
            <tr>
              <th>生年月日</th>
              <td>{birthDate || 'N/A'}</td>
            </tr>
            <tr>
              <th>職業形態</th>
              <td>{occupation || 'N/A'}</td>
            </tr>
            <tr>
              <th>住所</th>
              <td>{renderAddress(address)}</td>
            </tr>
            <tr>
              <th>利用目的</th>
              <td>{applicationReason || 'N/A'}</td>
            </tr>
            <PaidyLinkRow></PaidyLinkRow>
            <PacDataRows plusData={plusData}></PacDataRows>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default PlusDetail;
