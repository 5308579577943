import { useMutation } from '@apollo/react-hooks';
import React from 'react';
import { useParams } from 'react-router-dom';
import modalStyles from '../../Modal/Modal.module.scss';
import {
  cardEligibilityQuery,
  createCardEligibilityException,
} from './gql-query';
import { ErrorMessage, LoadingMessage } from './ModalMessages';
import { useUnmountableCustomModalContext } from './UnmountableCustomModal';

const useCreateCardEligibilityException = (consumerId: string) => {
  return useMutation(createCardEligibilityException, {
    variables: { consumerId },
    refetchQueries: () => [
      {
        query: cardEligibilityQuery,
        variables: { consumerId },
      },
    ],
  });
};

export const ApplyNewCardModal = () => {
  const { onCancel } = useUnmountableCustomModalContext();
  const { consumerId } = useParams() as { consumerId: string };

  const [mutate, { data, loading, error }] =
    useCreateCardEligibilityException(consumerId);

  const result = data?.createCardEligibilityException;

  if (result === 'NoNeedToMakeException') {
    return (
      <>
        <h2>Apply For A New Card</h2>
        <div>Result: Cannot create new card</div>

        <form onSubmit={(e) => e.preventDefault()}>
          <div className={modalStyles.actions}>
            <button type='button' className={'btn'} onClick={onCancel}>
              OK
            </button>
          </div>
        </form>
      </>
    );
  }

  return (
    <>
      <h2>Apply For A New Card</h2>
      <LoadingMessage loading={loading}></LoadingMessage>
      <ErrorMessage error={error}></ErrorMessage>
      {!loading && !error && (
        <div>Are you sure you want to apply for a new card?</div>
      )}

      <form onSubmit={(e) => e.preventDefault()}>
        <div className={modalStyles.actions}>
          {!error && !loading && (
            <button
              type='button'
              className={'btn red'}
              onClick={() => mutate()}
            >
              OK
            </button>
          )}

          {!loading && (
            <button type='button' className={'btn'} onClick={onCancel}>
              キャンセル
            </button>
          )}
        </div>
      </form>
    </>
  );
};
