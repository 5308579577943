import React, { FC, PropsWithChildren, ReactNode } from 'react';
import { useFraudPermissionQuery } from '../../utils/hooks/use-fraud-permission';

export interface WithPermissionCheckerProps {
  permissions: string[];
  placeholder?: React.ReactNode;
  children?: ReactNode | undefined;
}

export const withPermissionChecker = <P extends object>(
  WrappedComponent: React.ComponentType<P>
) => {
  return (props: P & WithPermissionCheckerProps) => {
    const { permissions, placeholder, ...rest } = props;
    const allPermissions = useFraudPermissionQuery();

    const isAlowed = permissions.every((permission) =>
      allPermissions.includes(permission)
    );
    if (!isAlowed) {
      return <>{placeholder || null}</>;
    }

    return <WrappedComponent {...(rest as P)} />;
  };
};

export const PermissionChecker: FC<
  PropsWithChildren<WithPermissionCheckerProps>
> = withPermissionChecker(
  ({ children }: { children?: ReactNode | undefined }) => {
    return <>{children}</>;
  }
);
