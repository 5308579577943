import React from 'react';
import modalStyles from '../../Modal/Modal.module.scss';
import { ErrorMessage, LoadingMessage } from '../PlusDetail/ModalMessages';
import { useUnmountableCustomModalContext } from '../PlusDetail/UnmountableCustomModal';
import { useCancelContract, useHoukatsuContext } from './HoukatsuContext';

type CancelContractModalProps = {
  id: string;
};

export const CancelContractModal = ({ id }: CancelContractModalProps) => {
  const { onCancel, onConfirm } = useUnmountableCustomModalContext();
  const { consumerId } = useHoukatsuContext();

  const onCompleted = () => {
    onConfirm();
  };

  const [mutate, { loading, error }] = useCancelContract(
    consumerId,
    id,
    onCompleted
  );

  const disabled = loading;

  const disableClassName = (classNameString: string) =>
    disabled ? 'btn disabled' : classNameString;

  return (
    <>
      <h2>一括払いに戻す</h2>
      <LoadingMessage loading={loading}></LoadingMessage>
      <ErrorMessage error={error}></ErrorMessage>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className={modalStyles.actions}>
          <button
            disabled={disabled}
            onClick={() => mutate()}
            className={disableClassName('btn red')}
          >
            確定する
          </button>
          <button
            disabled={disabled}
            onClick={() => onCancel()}
            className={disableClassName('btn blue')}
          >
            キャンセル
          </button>
        </div>
      </form>
    </>
  );
};
