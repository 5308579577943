import gql from 'graphql-tag';

export default gql`
  query ConsumerMessages($consumerId: ID!) {
    consumerMessages(consumerId: $consumerId) {
      subject
      message
      createdAt
      kind
      dispatched
    }
  }
`;
