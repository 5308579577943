import { useQuery } from '@apollo/react-hooks';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getTimeString, onError } from '../../../utils';
import LoadingPartial from '../../Loading/LoadingPartial';
import CreatingAdminLog from '../CreatingAdminLog/CreatingAdminLog';
import gqlQuery from './gql-query';
import styles from './Logs.module.scss';

const Logs = () => {
  const { consumerId } = useParams() as { consumerId: string };
  const [adminlogs, setAdminlogs] = useState([]);
  const [isCreatingAdminLog, setIsCreatingAdminLog] = useState(false);
  const [createdAdminlog, setCreatedAdminlog] = useState();
  const { loading, data } = useQuery(gqlQuery, {
    onError,
    variables: { consumerId },
  });

  useEffect(() => {
    if (data && data.adminlogs) {
      setAdminlogs(data.adminlogs);
    }
  }, [data]);

  useEffect(() => {
    if (createdAdminlog) {
      const newAdminlogs = (adminlogs && adminlogs.slice()) || [];

      newAdminlogs.unshift(createdAdminlog);
      setAdminlogs(newAdminlogs);
    }
  }, [createdAdminlog]);

  if (loading) {
    return <LoadingPartial />;
  }

  if (!adminlogs || !adminlogs.length) {
    return (
      <>
        <h3>
          &nbsp;
          <button
            className='btn secondary'
            onClick={() => setIsCreatingAdminLog(true)}
          >
            ログを作成
          </button>
        </h3>
        <div className='na-box' />
        <CreatingAdminLog
          isOpen={isCreatingAdminLog}
          setIsOpen={setIsCreatingAdminLog}
          consumerId={consumerId}
          setCreatedAdminlog={setCreatedAdminlog}
        />
      </>
    );
  }

  return (
    <>
      <div
        className='flex'
        style={{ marginBottom: 20, justifyContent: 'flex-end' }}
      >
        <button
          className='btn secondary'
          onClick={() => setIsCreatingAdminLog(true)}
        >
          ログを作成
        </button>
      </div>
      <div className='table'>
        <table className={styles['log-table']}>
          <thead>
            <tr>
              <th>作成日</th>
              <th>作成者</th>
              <th>メッセージ</th>
            </tr>
          </thead>
          <tbody>
            {adminlogs
              .sort((x: any, y: any) => y.createdAt - x.createdAt)
              .map((log: any, i) => {
                return (
                  <tr key={i}>
                    <td className={styles['time']}>
                      <time>{getTimeString(new Date(log.createdAt))}</time>
                    </td>
                    <td>{log.creator}</td>
                    <td className={styles['log']}>{log.message}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <CreatingAdminLog
        isOpen={isCreatingAdminLog}
        setIsOpen={setIsCreatingAdminLog}
        consumerId={consumerId}
        setCreatedAdminlog={setCreatedAdminlog}
      />
    </>
  );
};

export default Logs;
