import cx from 'classnames';
import React from 'react';
import styles from './SCSFlag.module.scss';

type Props = {
  show?: boolean | null;
};

const SCSFlag = ({ show }: Props) => {
  if (show === null) {
    return (
      <span className={cx(styles.flag, styles.err)}>
        リフレッシュしてください
      </span>
    );
  }

  return show ? <span className={styles.flag}>特定継続的役務</span> : null;
};

export default SCSFlag;
