import React from 'react';
import modalStyles from '../../Modal/Modal.module.scss';
import styles from '../ConsumerPage.module.scss';
import { ErrorMessage, LoadingMessage } from './ModalMessages';
import {
  ResetHoukatsuStatusProvider,
  useResetHoukatsuStatusContext,
  useResetKYCStatus,
} from './ResetKYCStatusContext';
import {
  UnmountableCustomModalProvider,
  UnmountableModalWrapper,
} from './UnmountableCustomModal';

type KYCHoukatsuStatusRowProps = {
  children: React.ReactNode;
};

const KYCHoukatsuStatusRow = ({ children }: KYCHoukatsuStatusRowProps) => {
  const { mappedEKYCStatus, mappedHoukatsuStatus, mappedAppleStatus } =
    useResetHoukatsuStatusContext();

  return (
    <tr>
      <th>ステイタス</th>
      <td className={styles.flex}>
        <div className={styles.grow}>
          <table>
            <tbody>
              <tr>
                <th>KYC</th>
                <td>{mappedEKYCStatus}</td>
              </tr>
              <tr>
                <th>包括</th>
                <td>{mappedHoukatsuStatus}</td>
              </tr>
              <tr>
                <th>Apple</th>
                <td>{mappedAppleStatus}</td>
              </tr>
            </tbody>
          </table>
        </div>
        {children}
      </td>
    </tr>
  );
};

const ResetHoukatsuStatus = () => {
  const { onOpen, inactive } = useResetHoukatsuStatusContext();

  return (
    <div className={styles['button-group']}>
      <button
        className={`btn ${inactive ? 'disabled' : ''}`}
        disabled={inactive}
        onClick={() => onOpen()}
      >
        再申込を可能にする
      </button>
    </div>
  );
};

const ResetHoukatsuStatusModal = () => {
  const { onConfirm, onCancel, consumerId } = useResetHoukatsuStatusContext();
  const [mutate, { loading, error }] = useResetKYCStatus(consumerId, () =>
    onConfirm()
  );

  const disabled = loading;

  const disableClassName = (classNameString: string) =>
    disabled ? 'btn disabled' : classNameString;

  return (
    <>
      <h2>再申込を可能にする</h2>
      <LoadingMessage loading={loading}></LoadingMessage>
      <ErrorMessage error={error}></ErrorMessage>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className={modalStyles.actions}>
          <button
            disabled={disabled}
            onClick={() => mutate()}
            className={disableClassName('btn red')}
          >
            確定する
          </button>
          <button
            disabled={disabled}
            onClick={() => onCancel()}
            className={disableClassName('btn blue')}
          >
            キャンセル
          </button>
        </div>
      </form>
    </>
  );
};

const KYCHoukatsuStatus = () => {
  return (
    <UnmountableCustomModalProvider>
      <ResetHoukatsuStatusProvider>
        <KYCHoukatsuStatusRow>
          <ResetHoukatsuStatus></ResetHoukatsuStatus>
        </KYCHoukatsuStatusRow>
        <UnmountableModalWrapper>
          <ResetHoukatsuStatusModal></ResetHoukatsuStatusModal>
        </UnmountableModalWrapper>
      </ResetHoukatsuStatusProvider>
    </UnmountableCustomModalProvider>
  );
};

export default KYCHoukatsuStatus;
