import React from 'react';
import { useQuery } from 'react-apollo';
import { useParams } from 'react-router-dom';
import { cardStatus, cardStatusMap } from '../../../constants';
import { capitalize, getTimeString } from '../../../utils';
import { ApplyNewCardModal } from './ApplyNewCardModal';
import { cardEligibilityQuery, gpsQuery, query } from './gql-query';
import styles from './PaidyLinkRow.module.scss';
import {
  UnmountableCustomModalProvider,
  UnmountableModalWrapper,
  useUnmountableCustomModalContext,
} from './UnmountableCustomModal';
import { UpdateGPSTokenStatusModal } from './UpdateGPSTokenStatusModal';

type GPSTokenType = {
  token: string;
  status: string;
  createdAt: number;
  idemiaId: string;
  physicalCardStatus: string;
  physicalCardUpdatedAt: string;
  physicalCardEvents: {
    physicalCardStatus: string;
    physicalCardUpdatedAt: string;
  }[];
};

type UpdateStatusButtonProps = {
  text: string;
  className: string;
  token: string;
  statusToUpdate: string;
  updateGpsToken: boolean;
};

const mapStatus = (status: string) => {
  switch (status) {
    case 'linked':
      return '連携中';
    case 'unlinked':
      return '連携無';
    default:
      return 'unknown';
  }
};

function usePaidyLinkQuery() {
  const { consumerId } = useParams() as { consumerId: string };

  const {
    data: incoming,
    loading: linkLoading,
    error: linkError,
  } = useQuery(query, {
    variables: { consumerId },
  });

  const data = React.useMemo(
    () =>
      incoming
        ? {
            ...incoming,
            paypalLinks: incoming?.paypalLinks
              .map((link: any) => ({
                ...link,
                linkEvents: link.linkEvents.sort(
                  (a: any, b: any) => a.time - b.time
                ),
              }))
              .sort(
                (a: any, b: any) =>
                  a.linkEvents?.[0].time - b.linkEvents?.[0].time
              ),
          }
        : incoming,
    [incoming]
  );

  const latest = data?.paypalLinks?.[data.paypalLinks.length - 1];

  const linked = latest?.linkEvents.filter(
    (event: any) => event.status === 'linked'
  );
  const linkedLatestAt = linked?.[linked.length - 1]?.time;

  const unlinked = latest?.linkEvents.filter(
    (event: any) => event.status === 'unlinked'
  );
  const unlinkedLatestAt = unlinked?.[unlinked.length - 1]?.time;

  return {
    data,
    linkLoading,
    linkError,
    linkedLatestAt,
    unlinkedLatestAt,
    latest,
  };
}

function useGPSQuery() {
  const { consumerId } = useParams() as { consumerId: string };

  const {
    data,
    loading: gpsLoading,
    error: gpsError,
  } = useQuery(gpsQuery, {
    variables: { consumerId },
  });

  const gpsTokens = data?.gpsTokens || [];

  return { gpsTokens, gpsLoading, gpsError };
}

function useCardEligibilityQuery() {
  const { consumerId } = useParams() as { consumerId: string };

  const {
    data,
    loading: cardEligibilityLoading,
    error: cardEligibilityError,
  } = useQuery(cardEligibilityQuery, {
    variables: { consumerId },
  });

  const isEligible = data?.cardEligibility?.isEligible || false;

  return { isEligible, cardEligibilityLoading, cardEligibilityError };
}

export function PaidyLink() {
  const { linkLoading, linkError, latest, linkedLatestAt, unlinkedLatestAt } =
    usePaidyLinkQuery();

  const { gpsTokens, gpsLoading, gpsError } = useGPSQuery();

  const { isEligible, cardEligibilityLoading, cardEligibilityError } =
    useCardEligibilityQuery();

  if (linkLoading || gpsLoading || cardEligibilityLoading) return null;

  if (linkError || gpsError || cardEligibilityError) return <div>N/A</div>;

  if (gpsTokens.length === 0) return null;

  return (
    <table className={styles['paidy-link-table']}>
      <tbody>
        <CardEligibility isEligible={isEligible} />

        <GPSDataRow gpsTokens={gpsTokens} />
        {latest && (
          <>
            <tr className={styles['blank-row']}>
              <td colSpan={3}></td>
            </tr>
            <LinkDataRow
              latest={latest}
              linkedLatestAt={linkedLatestAt}
              unlinkedLatestAt={unlinkedLatestAt}
            />
          </>
        )}
      </tbody>
    </table>
  );
}

export function GPSDataRow({ gpsTokens }: { gpsTokens: GPSTokenType[] }) {
  if (gpsTokens.length === 0) return null;

  return (
    <>
      {gpsTokens.map((gps) => (
        <GpsTokenDetails gps={gps} key={gps.token} />
      ))}
    </>
  );
}

export function GpsTokenDetails({ gps }: { gps: GPSTokenType }) {
  const {
    token,
    createdAt,
    status,
    idemiaId,
    physicalCardStatus,
    physicalCardEvents,
  } = gps;

  return (
    <React.Fragment>
      <tr>
        <th rowSpan={5}>GPS Token</th>
        <th>Token</th>
        <td>{token || 'N/A'}</td>
      </tr>
      <tr>
        <th>Created at</th>
        <td>{getTimeString(new Date(createdAt)) || 'N/A'}</td>
      </tr>
      <tr>
        <th>Status</th>
        <td>
          <TokenStatus token={token} status={status} />
        </td>
      </tr>
      <tr>
        <th>IdemiaID</th>
        <td>{idemiaId}</td>
      </tr>
      <tr>
        <th>Status / Date</th>
        <td>
          {
            <IdemiaIDStatus
              token={token}
              cardStatus={physicalCardStatus}
              cardEvents={physicalCardEvents.sort(
                (a: any, b: any) =>
                  a.physicalCardUpdatedAt - b.physicalCardUpdatedAt
              )}
            />
          }
        </td>
      </tr>
    </React.Fragment>
  );
}

export function TokenStatus({
  token,
  status,
}: {
  token: string;
  status: string;
}) {
  if (status === cardStatus.destroyed) {
    return (
      <div className={styles['token-status-row']}>
        <div>{cardStatusMap[status] || 'N/A'}</div>
      </div>
    );
  }

  return (
    <div className={styles['token-status-row']}>
      <div>{cardStatusMap[status] || 'N/A'}</div>
      <div className={styles['token-actions']}>
        {status !== cardStatus.frozenByAdmin && (
          <UnmountableCustomModalProvider>
            <UpdateStatusButton
              text='Void Card'
              token={token}
              updateGpsToken={true}
              statusToUpdate={cardStatus.frozenByAdmin}
              className={`btn ${styles['void-btn']}`}
            />
          </UnmountableCustomModalProvider>
        )}

        {status === cardStatus.frozenByAdmin && (
          <UnmountableCustomModalProvider>
            <UpdateStatusButton
              text='Active Card'
              token={token}
              updateGpsToken={true}
              statusToUpdate={cardStatus.active}
              className={`btn ${styles['active-btn']}`}
            />
          </UnmountableCustomModalProvider>
        )}
        <UnmountableCustomModalProvider>
          <UpdateStatusButton
            text='Delete Card'
            token={token}
            updateGpsToken={true}
            statusToUpdate={cardStatus.destroyed}
            className={`btn ${styles['delete-btn']}`}
          />
        </UnmountableCustomModalProvider>
      </div>
    </div>
  );
}

export function IdemiaIDStatus({
  token,
  cardStatus,
  cardEvents,
}: {
  token: string;
  cardStatus: string;
  cardEvents: any[];
}) {
  const formatStatus = (status: string) => {
    switch (status) {
      case 'cardSent':
        return 'Card Sent';
      case 'notApplied':
        return 'Not Applied';
      default:
        return capitalize(status);
    }
  };

  // Show button if the lastest card status is cardSent
  const showUpdateStatusButton = (status: string, index: number) => {
    return status === 'cardSent' && index === cardEvents.length - 1;
  };

  // Case: Not Applied
  if (cardEvents.length === 0) {
    return (
      <div className={styles['token-status-row']}>
        <div>
          <span>{formatStatus(cardStatus)}</span>
        </div>
      </div>
    );
  }

  // Other Cases
  return (
    <>
      {cardEvents.map((event, i) => {
        const { physicalCardStatus, physicalCardUpdatedAt } = event;

        return (
          <div
            className={styles['token-status-row']}
            key={physicalCardUpdatedAt}
          >
            <div>
              <span>{formatStatus(physicalCardStatus)}</span>
              <span>
                {physicalCardUpdatedAt
                  ? ` at ${getTimeString(new Date(physicalCardUpdatedAt))}`
                  : ` at N/A`}
              </span>
            </div>
            {showUpdateStatusButton(physicalCardStatus, i) && (
              <UnmountableCustomModalProvider>
                <UpdateStatusButton
                  text="Change status to 'returned'"
                  token={token}
                  updateGpsToken={false}
                  statusToUpdate={'returned'}
                  className={`btn ${styles['active-btn']}`}
                />
              </UnmountableCustomModalProvider>
            )}
          </div>
        );
      })}
    </>
  );
}

export function UpdateStatusButton({
  text,
  className,
  token,
  statusToUpdate,
  updateGpsToken,
}: UpdateStatusButtonProps) {
  const { onOpen } = useUnmountableCustomModalContext();

  return (
    <>
      <button className={className} onClick={onOpen}>
        {text}
      </button>
      <UnmountableModalWrapper>
        <UpdateGPSTokenStatusModal
          updateGpsToken={updateGpsToken}
          text={text}
          token={token}
          statusToUpdate={statusToUpdate}
        />
      </UnmountableModalWrapper>
    </>
  );
}

export function LinkDataRow({
  latest,
  linkedLatestAt,
  unlinkedLatestAt,
}: {
  latest: any;
  linkedLatestAt: number;
  unlinkedLatestAt: number;
}) {
  if (!latest) return null;

  return (
    <>
      <tr>
        <th rowSpan={4}>Link with Paypal</th>
      </tr>
      <tr>
        <th>Status</th>
        <td>{latest.status ? mapStatus(latest.status) : 'N/A'}</td>
      </tr>
      <tr>
        <th>最終連携日</th>
        <td>
          {linkedLatestAt ? getTimeString(new Date(linkedLatestAt)) : 'N/A'}
        </td>
      </tr>
      <tr>
        <th>最終解除日</th>
        <td>
          {unlinkedLatestAt ? getTimeString(new Date(unlinkedLatestAt)) : 'N/A'}
        </td>
      </tr>
    </>
  );
}

export const CardEligibility = ({ isEligible }: { isEligible: boolean }) => {
  return (
    <tr>
      <th colSpan={2}>Card Re-issuance</th>
      <td colSpan={1}>
        {isEligible ? (
          'Eligible (発行可)'
        ) : (
          <div className={styles['new-card-actions']}>
            Not eligible (発行不可)
            <UnmountableCustomModalProvider>
              <ApplyNewCardButton />
            </UnmountableCustomModalProvider>
          </div>
        )}
      </td>
    </tr>
  );
};

export function ApplyNewCardButton() {
  const { onOpen } = useUnmountableCustomModalContext();

  return (
    <>
      <button className={`btn ${styles['add-card-btn']}`} onClick={onOpen}>
        Add one more
      </button>
      <UnmountableModalWrapper>
        <ApplyNewCardModal />
      </UnmountableModalWrapper>
    </>
  );
}

export default function PaidyLinkRow() {
  return (
    <tr>
      <th>どこでもペイディ</th>
      <td>
        <PaidyLink></PaidyLink>
      </td>
    </tr>
  );
}
