import gql from 'graphql-tag';

// TODO: confirm if "canCancel" is required or not
export default gql`
  query CombiniPayment($combiniPaymentId: ID!) {
    combinipayment(id: $combiniPaymentId) {
      id
      createdAt
      updatedAt
      expiresAt
      amount
      settlementFeeAmount
      test
      status
      buyer {
        name1
        name2
        email
        phone
      }
      orderRef
      provider {
        providerName
        providerRef
        consumerCode
        merchantCode
      }
      canCancel
    }
  }
`;
