import { useMutation } from '@apollo/react-hooks';
import React, { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import modalStyles from '../../Modal/Modal.module.scss';
import { ErrorMessage, LoadingMessage } from '../PlusDetail/ModalMessages';
import { useUnmountableCustomModalContext } from '../PlusDetail/UnmountableCustomModal';
import styles from './FraudPayments.module.scss';
import {
  getPaymentActiveFraudFlags,
  PaymentActiveFraudFlag,
  PaymentFraudUnflaggingReason,
  unflagFraudPayment,
} from './gql-query';

const useCreateFraudFlag = (
  consumerId: string,
  reasonId: string,
  fraudFlagId: string[],
  onCompleted: () => void
) => {
  return useMutation(unflagFraudPayment, {
    variables: { consumerId, unflaggedFor: reasonId, fraudFlagId },
    onCompleted,
    refetchQueries: () => [
      {
        query: getPaymentActiveFraudFlags,
        variables: { consumerId },
      },
    ],
  });
};

interface UnflagPaymentFraudModalProps {
  unflaggingReasons: PaymentFraudUnflaggingReason[];
  paymentFraudFlags: PaymentActiveFraudFlag[];
  onCompleted: () => void;
}

const UnflagPaymentFraudModal: FC<UnflagPaymentFraudModalProps> = ({
  unflaggingReasons,
  paymentFraudFlags,
  onCompleted,
}) => {
  const { onCancel, onConfirm } = useUnmountableCustomModalContext();
  const { consumerId } = useParams() as { consumerId: string };
  const [reasonId, setReasonId] = useState('');
  const [readyToSubmit, setReadyToSubmit] = useState(false);

  const onMutationCompleted = () => {
    onConfirm();
    onCompleted && onCompleted();
  };

  const [mutate, { loading, error }] = useCreateFraudFlag(
    consumerId,
    reasonId,
    paymentFraudFlags.reduce(
      (acc, next) => acc.concat(next.activeFraudFlagIds),
      [] as string[]
    ),
    onMutationCompleted
  );

  const disabled = loading;

  const getReasonById = (id: string) => {
    const currentReason = unflaggingReasons.find((reason) => reason.id === id);

    return currentReason ? currentReason.description : '';
  };

  const onSubmit = () => {
    if (!readyToSubmit) {
      setReadyToSubmit(true);
      return;
    }

    mutate();
  };

  return (
    <>
      <button onClick={onCancel} className={modalStyles['close-button']}>
        <img
          src={require('../../../assets/icon-close-modal.svg')}
          alt='close'
          width='24'
          height='24'
        />
      </button>
      <h2>Fraudフラグ解除する</h2>
      <LoadingMessage loading={loading}></LoadingMessage>
      <ErrorMessage error={error}></ErrorMessage>
      <form onSubmit={(e) => e.preventDefault()}>
        {!readyToSubmit && (
          <fieldset>
            <label>フラグ解除理由</label>
            <select
              defaultValue=''
              className={reasonId ? styles['select-active'] : ''}
              onChange={(event) => setReasonId(event.target.value)}
            >
              <option value='' disabled>
                選択して下さい
              </option>
              {unflaggingReasons.map((reason) => (
                <option key={reason.id} value={reason.id}>
                  {reason.description}
                </option>
              ))}
            </select>
          </fieldset>
        )}

        {readyToSubmit && !error && !loading && (
          <div>
            <p>下記のフラグ解除設定を確認してください。</p>
            <p>
              <strong>ペイメント数</strong>
            </p>
            <p>{paymentFraudFlags.length}</p>
            <p>
              <strong>フラグ解除理由</strong>
            </p>
            <p>• {getReasonById(reasonId)}</p>
          </div>
        )}

        <div className={`${modalStyles.actions} ${styles['action-group']}`}>
          <button
            disabled={!reasonId || disabled}
            className={`btn red`}
            onClick={onSubmit}
          >
            フラグ解除
          </button>
          <button
            disabled={disabled}
            className={`btn primary`}
            onClick={onCancel}
          >
            キャンセル
          </button>
        </div>
      </form>
    </>
  );
};

export default UnflagPaymentFraudModal;
