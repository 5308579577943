import React, { FC, PropsWithChildren, ReactNode } from 'react';
import styles from './TableRow.module.scss';

const TableRow: FC<
  PropsWithChildren<{
    title: string | ReactNode;
    containerClassName?: string;
  }>
> = ({ title, containerClassName, children }) => {
  return (
    <table className={`${styles.table} ${containerClassName ?? ''}`}>
      <thead className={styles.head}>
        <tr>
          <td>{title}</td>
        </tr>
      </thead>
      <tbody className={styles.body}>{children}</tbody>
    </table>
  );
};

export default TableRow;
