import React, { SyntheticEvent, useEffect, useState } from 'react';
import axios from '../../../axios';
import { onError, showNotification, validateAdminlog } from '../../../utils';
import Modal from '../../Modal/Modal';
import modalStyles from '../../Modal/Modal.module.scss';
import styles from './CreatingAdminLog.module.scss';

type CreatingAdminLogProps = {
  isOpen: boolean;
  setIsOpen: Function;
  consumerId: string;
  setCreatedAdminlog: Function;
};

const CreatingAdminLog = ({
  isOpen,
  setIsOpen,
  consumerId,
  setCreatedAdminlog,
}: CreatingAdminLogProps) => {
  const [log, setLog] = useState('');
  const [logError, setLogError] = useState('');

  useEffect(() => {
    setLogError('');
  }, [isOpen]);

  const onSubmit = async (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();

    const errorMsg = validateAdminlog(log);

    if (errorMsg) {
      setLogError(errorMsg);

      return;
    }

    const safeLog = log.replace(/\n/g, '\\n');
    const query = `
      {
        createAdminlog(consumerId: "${consumerId}", message: "${safeLog}", test: false) {
          createdAt
          creator
          message
        }
      }
    `;

    try {
      const result = await axios.post(`/graphql`, {
        operationName: null,
        query,
        variables: {},
      });

      showNotification('新しいログが追加されました', 'success');
      setIsOpen(false);

      if (result?.data?.data?.createAdminlog) {
        setCreatedAdminlog(result.data.data.createAdminlog[0]);
      }

      setLog('');
    } catch (error) {
      onError(error);
      showNotification(`Failed to create log`, 'error');
    }
  };

  return (
    <Modal className={modalStyles['modal-large']} isOpen={isOpen}>
      <button
        onClick={() => setIsOpen(false)}
        className={modalStyles['close-button']}
      >
        <img
          src={require('../../../assets/icon-close-modal.svg')}
          alt='close'
          width='24'
          height='24'
        />
      </button>
      <h2>新しいログを作成</h2>
      <form onSubmit={onSubmit}>
        <fieldset>
          <label>メッセージ</label>
          <textarea
            className={styles.textarea}
            rows={15}
            placeholder='ログ内容を入力してください。（改行はできません）'
            value={log}
            onChange={(event) => setLog(event.currentTarget.value)}
          />
          {logError && <p className={modalStyles.error}>{logError}</p>}
        </fieldset>
        <div className={modalStyles.actions}>
          <input type='submit' className='btn' value='追加' />
        </div>
      </form>
    </Modal>
  );
};

export default CreatingAdminLog;
