import { useMutation } from '@apollo/react-hooks';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import modalStyles from '../../Modal/Modal.module.scss';
import { ErrorMessage, LoadingMessage } from '../PlusDetail/ModalMessages';
import { useUnmountableCustomModalContext } from '../PlusDetail/UnmountableCustomModal';
import styles from './Fraud.module.scss';
import { deleteFraudFlag, getFraudEventLogs, getFraudFlags } from './gql-query';

type DeleteFraudFlagModalProps = {
  flagId: string;
};

const useDeleteFraudFlag = (
  consumerId: string,
  flagId: string,
  message: string,
  onCompleted: () => void
) => {
  return useMutation(deleteFraudFlag, {
    variables: { consumerId, flagId, message },
    onCompleted,
    refetchQueries: () => [
      {
        query: getFraudEventLogs,
        variables: { consumerId },
      },
    ],
    update: (cache: any, res) => {
      if (res && res.data && res.data.unflagConsumerFromFraudFlag !== 'Success')
        return;

      const variables = { consumerId };

      const { activeFraudFlagsByConsumerId = [] } = cache.readQuery({
        query: getFraudFlags,
        variables,
      });

      const data = {
        activeFraudFlagsByConsumerId: activeFraudFlagsByConsumerId.filter(
          (flag: any) => flag.id !== flagId
        ),
      };

      cache.writeQuery({
        query: getFraudFlags,
        variables,
        data,
      });
    },
  });
};

const DeleteFraudFlagModal = ({ flagId }: DeleteFraudFlagModalProps) => {
  const { onCancel, onConfirm } = useUnmountableCustomModalContext();
  const { consumerId } = useParams() as { consumerId: string };

  const [message, setMessage] = useState('');
  const [readyToSubmit, setReadyToSubmit] = useState(false);
  const onCompleted = onConfirm;

  const [mutate, { loading, error }] = useDeleteFraudFlag(
    consumerId,
    flagId,
    message,
    onCompleted
  );

  const disabled = loading;

  const onSubmit = () => {
    if (!readyToSubmit) {
      setReadyToSubmit(true);
      return;
    }

    mutate();
  };

  return (
    <>
      <button onClick={onCancel} className={modalStyles['close-button']}>
        <img
          src={require('../../../assets/icon-close-modal.svg')}
          alt='close'
          width='24'
          height='24'
        />
      </button>
      <h2>Fraudフラグ解除する</h2>
      <LoadingMessage loading={loading}></LoadingMessage>
      <ErrorMessage error={error}></ErrorMessage>
      <form onSubmit={(e) => e.preventDefault()}>
        {!readyToSubmit && (
          <fieldset>
            <label>Fraudフラグ解除する理由</label>
            <textarea
              className={styles['fraud-textarea']}
              rows={3}
              placeholder='解除理由を入力してください。（改行はできません）'
              value={message}
              onChange={(event) => setMessage(event.currentTarget.value)}
              data-testid='textarea'
            />
          </fieldset>
        )}

        {readyToSubmit && !error && !loading && (
          <p>
            このアカウントのFraudフラグを解除しますか？Fraudフラグを解除した後も、アカウントのステータスはDISABLEDのままとなります。DISABLEDのステータスを変更するには、フラグ解除を完了した後に、顧客情報ページで手動で変更してください。
          </p>
        )}

        <div className={`${modalStyles.actions} ${styles['action-group']}`}>
          <button
            disabled={disabled}
            className={`btn ${styles['btn-secondary']}`}
            onClick={onSubmit}
            data-testid='submit-btn'
          >
            フラグ解除
          </button>
          <button
            disabled={disabled}
            className={`btn ${styles['btn-primary']}`}
            onClick={onCancel}
          >
            キャンセル
          </button>
        </div>
      </form>
    </>
  );
};

export default DeleteFraudFlagModal;
