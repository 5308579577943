import { useQuery } from '@apollo/react-hooks';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { NUM_ITEMS_PER_PAGE } from '../../../constants';
import { getTimeString, pagerize } from '../../../utils';
import {
  useIsEnableAssessmentWrite,
  useIsShowAssessmentTable,
} from '../../../utils/hooks/use-fraud-permission';
import Pagination from '../../Pagination/Pagination';
import {
  UnmountableCustomModalProvider,
  UnmountableModalWrapper,
  useUnmountableCustomModalContext,
} from '../PlusDetail/UnmountableCustomModal';
import CreateAssessmentModal from './CreateAssessmentModal';
import DeleteAssessmentModal from './DeleteAssessmentModal';
import styles from './Fraud.module.scss';
import { getAssessmentKinds, getAssessments } from './gql-query';

type CreateAssessmentButtonProps = { disabled: boolean };
type DeleteAssessmentButtonProps = { id: string };
type AssessmentRowProps = {
  id: string;
  assessmentKind: string;
  excludedBy: string;
  excludedAt: number;
};

const useAssessments = () => {
  const { consumerId } = useParams() as { consumerId: string };

  const {
    data = {},
    loading,
    error,
  } = useQuery(getAssessments, {
    variables: { consumerId },
  });

  const assessments =
    data?.assessmentExclusionsByConsumerId?.assessmentExclusions || [];

  return { assessments, loading, error };
};

const useAssessmentKinds = () => {
  const { consumerId } = useParams() as { consumerId: string };

  const {
    data = {},
    loading,
    error,
  } = useQuery(getAssessmentKinds, {
    variables: { consumerId },
  });

  const assessmentKinds = data?.excludableAssessmentsByConsumerId?.kinds || [];

  return { assessmentKinds, loading, error };
};

export const CreateAssessmentButton = ({
  disabled,
}: CreateAssessmentButtonProps) => {
  const { onOpen } = useUnmountableCustomModalContext();
  const { assessmentKinds = [] } = useAssessmentKinds();
  const isEnableAssessmentWrite = useIsEnableAssessmentWrite();

  return (
    <>
      <button
        className={`btn ${styles['btn-primary']}`}
        onClick={onOpen}
        disabled={!isEnableAssessmentWrite || disabled}
      >
        フラグ追加
      </button>
      <UnmountableModalWrapper className={styles['assessment-modal']}>
        <CreateAssessmentModal assessmentKinds={assessmentKinds} />
      </UnmountableModalWrapper>
    </>
  );
};

export const DeleteAssessmentButton = ({ id }: DeleteAssessmentButtonProps) => {
  const { onOpen } = useUnmountableCustomModalContext();
  const isEnableAssessmentWrite = useIsEnableAssessmentWrite();

  return (
    <>
      <button
        className={`btn ${styles['btn-primary']}`}
        onClick={onOpen}
        disabled={!isEnableAssessmentWrite}
      >
        フラグ解除
      </button>
      <UnmountableModalWrapper className={styles['assessment-modal']}>
        <DeleteAssessmentModal exclusionId={id} />
      </UnmountableModalWrapper>
    </>
  );
};

export const AssessmentRow = ({
  id,
  assessmentKind,
  excludedBy,
  excludedAt,
}: AssessmentRowProps) => {
  return (
    <tr>
      <td>{id}</td>
      <td>{getTimeString(new Date(excludedAt))}</td>
      <td>{assessmentKind}</td>
      <td>{excludedBy}</td>
      <td className={`${styles['fraud-table__btn-column']}`}>
        <UnmountableCustomModalProvider>
          <DeleteAssessmentButton id={id} />
        </UnmountableCustomModalProvider>
      </td>
    </tr>
  );
};

const AssessmentTable = () => {
  const { assessments = [], loading } = useAssessments();
  const isShowAssessmentTable = useIsShowAssessmentTable();

  const [page, setPage] = useState(0);

  const pageCount = assessments
    ? Math.ceil(assessments.length / NUM_ITEMS_PER_PAGE)
    : 0;

  const pagedAssessments = pagerize({
    entries: assessments,
    page,
    perPage: NUM_ITEMS_PER_PAGE,
  });

  const gotoPage = (p: number) => {
    setPage(p);
  };

  useEffect(() => {
    // page starts from 0
    // pageCount starts from 1
    if (page != 0 && page > pageCount - 1) {
      setPage(pageCount - 1);
    }
  }, [assessments]);

  return (
    <div className={`table ${styles['fraud-table']}`}>
      <span className={styles['fraud-table__heading']}>
        <h1>Assessment除外フラグ設定</h1>
        <UnmountableCustomModalProvider>
          <CreateAssessmentButton disabled={loading} />
        </UnmountableCustomModalProvider>
      </span>
      <table>
        <thead>
          <tr>
            <th>Assessment除外フラグID</th>
            <th>フラグ作成日</th>
            <th>Assessment</th>
            <th>作成者</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {isShowAssessmentTable ? (
            pagedAssessments.length ? (
              pagedAssessments.map((assessment) => (
                <AssessmentRow
                  key={assessment.id}
                  id={assessment.id}
                  assessmentKind={assessment.assessmentKind}
                  excludedBy={assessment.excludedBy}
                  excludedAt={assessment.excludedAt}
                />
              ))
            ) : (
              <tr>
                <td colSpan={5} className={styles['fraud-table__empty-row']}>
                  Assessment除外フラグがありません
                </td>
              </tr>
            )
          ) : (
            <tr>
              <td colSpan={5} className={styles['fraud-table__empty-row']}>
                Assessment除外機能にアクセスする権限がありません
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {pageCount > 1 && isShowAssessmentTable && (
        <Pagination pageCount={pageCount} page={page} gotoPage={gotoPage} />
      )}
    </div>
  );
};

export default AssessmentTable;
