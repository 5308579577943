import { ApolloProvider } from '@apollo/react-hooks';
import idx from 'idx';
import React, { Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import { Outlet } from 'react-router-dom';
import axios from './axios';
import { ErrorBoundary } from './components/ErrorBoundary/ErrorBoundary';
import Header from './components/Header/Header';
import Loading from './components/Loading/Loading';
import LoginPage from './components/LoginPage/LoginPage';
import { clientCreator } from './graphql';
import { setUserId } from './store/features/userSlice';

export const App = () => {
  const client = clientCreator();
  const userId = useSelector((state) => state.user.userId);
  const dispatch = useDispatch();

  const getUserEmail = async () => {
    try {
      const response = await axios.get(`/profile/me2`);
      const email = idx(response, (_) => _.data.email);
      if (email) {
        dispatch(setUserId(email));
      }
    } catch (err) {
      console.log({ err });
      dispatch(setUserId(''));
    }
  };

  useEffect(() => {
    getUserEmail();
  }, []);

  if (!userId) {
    return <LoginPage />;
  }

  return (
    <ApolloProvider client={client}>
      <Suspense fallback={<Loading />}>
        <ErrorBoundary>
          <Header />
          <main>
            <Outlet />
            <ToastContainer />
          </main>
        </ErrorBoundary>
      </Suspense>
    </ApolloProvider>
  );
};
